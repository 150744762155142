import React, {FormEvent, useState} from 'react';
import {
    Alert,
    Container,
    CssBaseline,
    FormControl,
    FormHelperText,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {NavBar} from './navbar';
import {authenticationService} from '../services/AuthenticationService';
import {Redirect} from "react-router-dom";
import {StyledButton} from './styledButton';
import {getUserRoles} from '../types/role';
import {UserModel} from '../types/user';
import {useUserManagementStyles} from '../utils/stylingHelper';

export const RegisterUser = () => {
    const classes = useUserManagementStyles();
    const register = authenticationService.useRegister();
    const [status, setStatus] = useState<string>("");
    const [userName, setUserName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [passwordConfirm, setPasswordConfirm] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [role, setRole] = useState<string>("");
    const [errors, setErrors] = useState<string[]>([]);

    // submit event
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setErrors([]);
        setStatus("loading");
        const newUser: UserModel = {
            userName: userName,
            email: email,
            password: password,
            passwordConfirm: passwordConfirm,
            firstName: firstName,
            lastName: lastName,
            role: role
        }
        register(newUser).then(response => {
            if (response.succeeded) {
                setStatus("success");
            } else {
                setStatus("");
                setErrors(response.errors);
            }
        });
    }

    const handleAbort = () => {
        setStatus("abort");
    }

    const handleRoleChange = (role: string) => {
        setRole(role);
    }

    const renderAlert = () => {
        if (!errors.length) {
            return;
        }

        return (
            <Alert severity="error" style={{marginTop: "1rem"}}>
                {errors.length === 1 ?
                    errors[0]
                    :
                    <ul>
                        {errors.map((error, index) => <li key={index}>{error}</li>)}
                    </ul>
                }
            </Alert>
        )
    }

    if (status === "success") {
        return <Redirect push to={{
            pathname: "/user-management",
            state: {successMsg: "Neuer Benutzer wurde erstellt"}
        }}/>
    }

    if (status === "abort") {
        return <Redirect push to="/user-management"/>
    }

    return (
        <React.Fragment>
            <NavBar basicHeaderOnly={true}></NavBar>
            <CssBaseline/>
            <Container maxWidth="sm">
                <Typography variant="h5" component="h1" className={classes.title}>Neuer Benutzer</Typography>
                {renderAlert()}
                <form onSubmit={handleSubmit} autoComplete="off">
                    <FormControl required
                                 fullWidth
                                 className={classes.formControl}>
                        <FormHelperText>Benutzername *</FormHelperText>
                        <TextField
                            variant="standard"
                            required={true}
                            autoFocus
                            autoComplete="off"
                            margin="dense"
                            id="username"
                            name="username"
                            type="text"
                            inputProps={{
                                maxLength: 120
                            }}
                            value={userName ?? ""}
                            onChange={e => setUserName(e.target.value)}
                        />
                    </FormControl>
                    <FormControl required
                                 fullWidth
                                 className={classes.formControl}>
                        <FormHelperText>E-Mail *</FormHelperText>
                        <TextField
                            variant="standard"
                            required={true}
                            autoFocus
                            autoComplete="off"
                            margin="dense"
                            id="email"
                            name="email"
                            type="email"
                            inputProps={{
                                maxLength: 120
                            }}
                            value={email ?? ""}
                            onChange={e => setEmail(e.target.value)}
                        />
                    </FormControl>
                    <FormControl required fullWidth className={classes.formControl}>
                        <FormHelperText>Passwort *</FormHelperText>
                        <TextField
                            variant="standard"
                            required={true}
                            autoComplete="off"
                            margin="dense"
                            id="password"
                            name="password"
                            type="password"
                            inputProps={{
                                maxLength: 120
                            }}
                            value={password ?? ""}
                            onChange={e => setPassword(e.target.value)}
                        />
                    </FormControl>
                    <FormControl required fullWidth className={classes.formControl}>
                        <FormHelperText>Passwort bestätigen *</FormHelperText>
                        <TextField
                            variant="standard"
                            required={true}
                            autoComplete="off"
                            margin="dense"
                            id="passwordconfirm"
                            name="passwordconfirm"
                            type="password"
                            inputProps={{
                                maxLength: 120
                            }}
                            value={passwordConfirm ?? ""}
                            onChange={e => setPasswordConfirm(e.target.value)}
                        />
                    </FormControl>
                    <FormControl required fullWidth className={classes.formControl}>
                        <FormHelperText>Vorname *</FormHelperText>
                        <TextField
                            variant="standard"
                            required={true}
                            autoComplete="off"
                            margin="dense"
                            id="firstname"
                            name="firstname"
                            type="text"
                            inputProps={{
                                maxLength: 120
                            }}
                            value={firstName ?? ""}
                            onChange={e => setFirstName(e.target.value)}
                        />
                    </FormControl>
                    <FormControl required fullWidth className={classes.formControl}>
                        <FormHelperText>Nachname *</FormHelperText>
                        <TextField
                            variant="standard"
                            required={true}
                            autoComplete="off"
                            margin="dense"
                            id="lastname"
                            name="lastname"
                            type="text"
                            inputProps={{
                                maxLength: 120
                            }}
                            value={lastName ?? ""}
                            onChange={e => setLastName(e.target.value)}
                        />
                    </FormControl>
                    <FormControl required fullWidth className={classes.formControl}>
                        <FormHelperText>Rolle *</FormHelperText>
                        <Select variant="standard" labelId="role" id="role" value={role}
                                onChange={e => handleRoleChange(e.target.value)}>
                            {getUserRoles().map((role, index) => (
                                <MenuItem value={role.key} key={index}>{role.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <StyledButton
                        type="button"
                        className={classes.secondaryButton}
                        onClick={handleAbort}
                        variant="outlined">
                        Abbrechen
                    </StyledButton>

                    <StyledButton
                        type="submit"
                        className={classes.primaryButton}>
                        Registrieren
                    </StyledButton>
                </form>
            </Container>
        </React.Fragment>
    );
}

export default RegisterUser;