import React, { useState, useEffect } from 'react';
import { useAxios } from '../utils/httpClient';
import { useStore } from '../state/store';
import { useRealtimeClient } from '../state/realtime.context';
import { Done } from '../components/done';
import { AxiosInstance } from 'axios';
import { IDone } from '../types/done';

function fetchTasks(
  httpClient: AxiosInstance,
  setDone: (done: IDone) => void,
  shouldGetAll = false,
  ownerId?: string,
  assigneeId?: string
) {
  httpClient
    .get('tasks/completed', {
      params: {
        All: shouldGetAll,
        CreatorId: ownerId,
        AssigneeId: assigneeId
      }
    })
    .then(res => setDone(res.data));
}

export const DoneContainer = () => {
  const httpClient = useAxios();
  const setDone = useStore(state => state.setDone);
  const done = useStore(state => state.done);
  const [areAllTasksVisible, setAreAllTasksVisible] = useState(false);
  const realtime = useRealtimeClient();
  const { ownerId, assigneeId } = useStore(state => ({ ownerId: state.ownerId, assigneeId: state.assigneeId }));

  useEffect(() => {
    fetchTasks(httpClient, setDone);
  }, []);

  useEffect(() => {
    const handleUpdate = () => {
      fetchTasks(httpClient, setDone, areAllTasksVisible, ownerId, assigneeId);
    };

    realtime.onUpdate(handleUpdate);

    return () => realtime.clear();
  }, [areAllTasksVisible, httpClient, ownerId, assigneeId]);

  const getAllTasks = () => {
    fetchTasks(httpClient, setDone, true, ownerId, assigneeId);
    setAreAllTasksVisible(true);
  };

  return (
    <>
      <Done
        done={done}
        getAllTasks={getAllTasks}
        showButton={!areAllTasksVisible}
        setData={setDone}
        setAreAllTasksVisible={setAreAllTasksVisible}
      />
    </>
  );
};
