import { ColumnIds } from '../types/mainBoard';
import { ITaskDetails } from '../types/tasks';

export function checkErrorsInForm(task: ITaskDetails, today: number): boolean {
  return checkRequiredFields(task) || checkIfEffortIsInvalid(task) || checkTitleLength(task);
}

export function checkIfEffortIsInvalid(task: ITaskDetails): boolean {
  return task.effort < -1;
}

export function checkDateTodayOrLater(today: number, selectedDateNoTime: number): boolean {
  return today > selectedDateNoTime;
}

export function checkRequiredFields(task: ITaskDetails): boolean {
  const defaultDate = '2000-01-01';
  return task.title === '' || task.tododate === defaultDate;
}

export function checkTitleLength(task: ITaskDetails): boolean {
  return task.title.length < 3;
}

export function getInitialDate(columnId: string | undefined): string {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  if (!columnId) return '';

  if (columnId == ColumnIds.tomorrow) {
    today.setDate(today.getDate() + 1);
  }

  return today.ConvertToYearMonthDayString();
}
