import React from 'react';
import Card from './card';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { BoardLayout } from './boardLayout';
import { IDone } from '../types/done';

const useStyles = makeStyles({
  container: {
    overflow: 'auto',
    border: '1px solid lightgrey',
    borderRadius: '5px',
    padding: '5px',
    margin: '8px',
    backgroundColor: '#EBECF0'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    marginBottom: '5px'
  },
  item: {
    margin: '5px',
    width: '17%'
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: '5px'
  }
});

interface IDoneProps {
  done: IDone;
  showButton: boolean;
  getAllTasks: () => void;
  setData: any;
  setAreAllTasksVisible: any;
}

export const Done: React.FC<IDoneProps> = ({ done, showButton, getAllTasks, setData, setAreAllTasksVisible }) => {
  const classes = useStyles();

  return (
    <BoardLayout name="completed" setData={setData} setAreAllTasksVisible={setAreAllTasksVisible}>
      <div className={classes.container}>
        <div className={classes.row}>
          {done?.items?.map(task => (
            <div className={classes.item} key={task.id}>
              <Card task={task} isDragging={false} columnTitle="done" />
            </div>
          ))}
        </div>
      </div>
      <div className={classes.button}>
        {showButton && (
          <Button onClick={getAllTasks} variant="contained" color="primary" style={{ color: 'white' }} size="large" disableElevation>
            Alle anzeigen
          </Button>
        )}
      </div>
    </BoardLayout>
  );
};
