import React, { ChangeEvent, useEffect, useState } from 'react';
import { useAxios } from '../utils/httpClient';
import { Guid } from 'guid-typescript';
import '../extensions/date';
import { ITaskDetails } from '../types/tasks';
import { DialogNewTask } from '../components/dialogNewTask';
import {
  checkDateTodayOrLater,
  checkErrorsInForm,
  checkIfEffortIsInvalid,
  checkRequiredFields,
  checkTitleLength,
  getInitialDate
} from '../utils/taskValidators';
import { SelectChangeEvent } from '@mui/material';
import { GET_TAGS_ROUTE, GetTagsResponse, TagsForAutocomplete } from '../types/tags';

interface IDialogNewTaskProps {
  isOpen: boolean;
  columnId?: string;
  closeSelf: () => void;
  taskId?: number;
}

export const CreateTaskContainer: React.FC<IDialogNewTaskProps> = ({ isOpen, columnId, closeSelf, taskId }) => {
  const httpClient = useAxios();

  const initialToDoDate = getInitialDate(columnId);
  const [task, setTask] = useState<ITaskDetails>({
    title: '',
    tododate: initialToDoDate,
    creatorId: Guid.createEmpty().toString,
    assigneeId: Guid.createEmpty().toString,
    effort: -1,
    description: ''
  });
  const [tags, setTags] = useState<string[]>([]);
  const [links, setLinks] = useState<string[]>([]);

  const [fieldDate, setFieldDate] = useState(task.tododate);
  const [alertRequired, setAlertRequired] = React.useState(false);
  const [alertDate, setAlertDate] = React.useState(false);
  const [alertEffort, setAlertEffort] = React.useState(false);
  const [alertTitleLength, setAlertTitleLength] = React.useState(false);
  const [creators, setCreators] = useState<Array<any>>([]);
  const [assignees, setAssignees] = useState<Array<any>>([]);
  const [loading, setLoading] = React.useState(false);
  const [existingTags, setExistingTags] = useState<TagsForAutocomplete>();

  useEffect(() => {
    if (taskId) {
      httpClient
        .get(`tasks/${taskId}`)
        .then(res => {
          const taskFromApi = res.data;
          setTask(taskFromApi);
          setFieldDate(taskFromApi.toDoDate);
          setTags(taskFromApi?.tags);
          setLinks(taskFromApi?.links);
        })
        .catch(console.error);
    }

    httpClient
      .get<GetTagsResponse>(GET_TAGS_ROUTE)
      .then(res => {
        const labels = res.data.items;
        setExistingTags(labels);
      })
      .catch(console.error);
  }, [taskId]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, prop: keyof ITaskDetails) => {
    setTask({ ...task, [prop]: event.target.value });
  };

  const handleChangeDate = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, prop: keyof ITaskDetails) => {
    setTask({ ...task, [prop]: event.target.value });
    setFieldDate(event.target.value);
  };

  const handleChangeEffort = (event: SelectChangeEvent<{ value: unknown }> | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    const effort = parseFloat(value as string).toFixed(2);

    setTask({ ...task, effort: parseFloat(effort) });
  };

  const handleChangeMenu = (
    event: SelectChangeEvent<{ value: unknown } | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>>,
    prop: keyof ITaskDetails
  ) => {
    setTask({ ...task, [prop]: event.target.value });
  };

  const emptyFields = () => {
    setTask({
      title: '',
      tododate: initialToDoDate,
      creatorId: Guid.createEmpty().toString,
      assigneeId: Guid.createEmpty().toString,
      effort: 0,
      description: ''
    });
    setFieldDate(task.tododate);
  };

  // Get assignees
  useEffect(() => {
    httpClient.get('users/profile').then(res => {
      const users = res.data.items;
      setAssignees(users);
      setCreators(users);
    });
  }, []);

  const handleClose = () => {
    emptyFields();
    setLoading(false);
    clearAlerts();
    closeSelf();
  };

  const clearAlerts = () => {
    setAlertRequired(false);
    setAlertDate(false);
    setAlertEffort(false);
    setAlertTitleLength(false);
  };

  const handleSubmit = async (e: React.MouseEvent) => {
    clearAlerts();
    e.preventDefault();

    const today = new Date().setHours(0, 0, 0, 0);
    const selectedDate = new Date(task.tododate);
    const selectedDateNoTime = selectedDate.setHours(0, 0, 0, 0);

    if (checkErrorsInForm(task, today)) {
      if (checkRequiredFields(task)) {
        setAlertRequired(true);
      }
      if (checkIfEffortIsInvalid(task)) {
        setAlertEffort(true);
      }
      if (checkTitleLength(task)) {
        setAlertTitleLength(true);
      }
    } else {
      if (checkDateTodayOrLater(today, selectedDateNoTime) && taskId == null) {
        setAlertDate(true);
      } else {
        setLoading(true);
        const taskDto: ITaskDetails & { tags: string[]; links: string[] } = { ...task, tags, links };
        
        if (taskDto.effort == -1) taskDto.effort = 0;

        try {
          if (taskId == null) {
            await httpClient.post('tasks', taskDto);
          } else {
            await httpClient.put(`tasks/${taskId}`, taskDto);
          }
        } finally {
          handleClose();
        }
      }
    }
  };

  const appendTag = (tag: string) => {
    if (tag != '' && tags.indexOf(tag) < 0) {
      setTags([...tags, tag]);
    }
  };

  const removeTag = (tagToRemove: string): void => {
    setTags(tags => tags.filter(tag => tag !== tagToRemove));
  };

  const addLink = (link: string) => {
    if (!/\S/.test(link)) {
      return;
    }
    if (!link.startsWith('http')) link = `https://${link}`;
    setLinks([...links, link]);
  };

  const removeLink = (linkToRemove: string): void => {
    setLinks(links => links.filter(link => link !== linkToRemove));
  };

  return (
    <DialogNewTask
      isOpen={isOpen}
      isLoading={loading}
      handleClose={handleClose}
      task={task}
      alerts={{
        alertDate,
        alertEffort,
        alertRequired,
        alertTitleLength
      }}
      fieldDate={fieldDate}
      creators={creators}
      assignees={assignees}
      handleChangeMenu={handleChangeMenu}
      handleChangeEffort={handleChangeEffort}
      handleSubmit={handleSubmit}
      handleChange={handleChange}
      handleChangeDate={handleChangeDate}
      addNewTag={appendTag}
      tags={tags}
      removeTag={removeTag}
      links={links}
      addNewLink={addLink}
      handleRemovedLink={removeLink}
      tagsForAutocomplete={existingTags}
    />
  );
};
