import { BoardUserSummaryResponse, TaskStatus } from './board';

export interface IBoardTask {
  id: number;
  title: string;
  effort: number;
  status: TaskStatus;
  toDoDate: Date;
  creator: BoardUserSummaryResponse;
  assignee: BoardUserSummaryResponse;
  tags?: string[];
  columnId?: number;
}

export interface ITaskDetails {
  id?: number;
  title: string;
  tododate: string;
  creatorId: any;
  assigneeId: any;
  effort: number;
  description: string;
  tags?: string[];
}

export const ValidTaskEfforts = {
  fifteenMinutes: '0.25',
  halfHour: '0.5',
  oneHour: '1',
  twoHours: '2',
  threeHours: '3',
  fourHours: '4',
  fiveHours: '5',
  other: '0'
};
