import { IBoardTask } from './tasks';

export interface ITask {
  id: string;
  title: string;
  owner: string;
  assigneePicture: string;
  ownerPicture: string;
}

export interface ITasks {
  [taskId: string]: ITask;
}

export interface IBoardSection {
  id: string;
  title: string;
  tasks: IBoardTask[];
  type: TaskStatus;
  isDropDisabled: (type: string) => boolean;
}

export enum TaskStatus {
  backlog = 1,
  inProgress = 2,
  review = 3,
  toBeFinished = 4,
  done = 5
}

export interface BoardUserSummaryResponse {
  id: string;
  firstName: string;
  lastName: string;
  profileImage: string;
  color: string;
}

export enum ColumnNames {
  tomorrow = 'Morgen',
  today = 'Heute',
  inProgress = 'In Arbeit',
  review = 'Review',
  toBeFinished = 'Fertigstellung',
  done = 'Done'
}