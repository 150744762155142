import {Theme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";

export const useUserManagementStyles: Function = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            marginTop: '3rem !important',
            marginBottom: '1rem'
        },
        subtitle: {
            marginTop: "1rem",
            marginBottom: ".5rem"
        },
        formControl: {
            marginTop: "1rem",
        },
        buttonWrapper: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between"
        },
        primaryButton: {
            marginTop: "1rem",
            color: 'white',
            background: theme.palette.primary.main,
            '&:hover': {
                background: theme.palette.secondary.main,
                color: 'white'
            }
        },
        secondaryButton: {
            marginTop: "1rem",
            marginRight: ".5rem",
            color: theme.palette.primary.main
        },
        navButton: {
            marginTop: "1rem",
            marginLeft: ".2rem",
            ":not(:first-child)": {
                marginRight: ".2rem"
            },
            padding: ".4rem",
            width: "48%",
            height: "5rem",
            color: 'white',
            background: theme.palette.primary.main,
            '&:hover': {
                background: theme.palette.secondary.main,
                color: 'white'
            }
        },
        secondaryNavButton: {
            marginTop: "1rem",
            marginLeft: ".2rem",
            padding: ".4rem",
            width: "48%",
            height: "5rem",
            color: theme.palette.primary.main
        },
        readonly: {
            backgroundColor: "#ddd",
        }
    })
);