import React, {ChangeEvent, useEffect, useState} from 'react';
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Theme
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {LoadingSpinner} from './loadingSpinner';
import {ITaskDetails, ValidTaskEfforts} from '../types/tasks';
import {TaskFormTags} from './taskFormTags';
import {TaskFormLinks} from './taskFormLinks';
import {StyledButton} from './styledButton';
import {TagsForAutocomplete} from '../types/tags';
import {TaskFormEffort} from "./taskFormEffort";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputElement: {
      overflow: 'hidden',
      padding: '5px',
      margin: '8px 0 8px 0'
    },
    saveButton: {
      color: 'white',
      background: theme.palette.primary.main,
      '&:hover': {
        background: theme.palette.secondary.main,
        color: 'white'
      }
    },
    row: {
      display: 'flex',
      flexDirection: 'row'
    }
  })
);

interface IAlerts {
  alertRequired: boolean;
  alertDate: boolean;
  alertEffort: boolean;
  alertTitleLength: boolean;
}

interface IDialogNewTaskProps {
  isOpen: boolean;
  isLoading: boolean;
  handleClose: () => void;
  task: ITaskDetails;
  alerts: IAlerts;
  fieldDate: string;
  creators: any[];
  assignees: any[];
  handleChangeMenu: (
    event: SelectChangeEvent<{ value: unknown } | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>>,
    prop: keyof ITaskDetails
  ) => void;
  handleChangeEffort: (event: SelectChangeEvent<{ value: unknown }> | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleSubmit: (e: React.MouseEvent) => void;
  handleChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, prop: keyof ITaskDetails) => void;
  handleChangeDate: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, prop: keyof ITaskDetails) => void;
  tags: string[];
  addNewTag: (tag: string) => void;
  removeTag: (tag: string) => void;
  links: string[];
  addNewLink: (links: string) => void;
  handleRemovedLink: (links: string) => void;
  tagsForAutocomplete: TagsForAutocomplete | undefined;
}

const errorMessages = {
  alertRequired: 'Bitte füllen Sie die Pflichtfelder(*) aus.',
  alertDate: 'To Do Datum sollte größer oder gleich dem heutigen Datum sein.',
  alertEffort: 'Arbeitsstunden sollte positiv sein.',
  alertTitleLength: 'The minimum length of title is 3 characters.'
};

function getAlertMessage(alerts: IAlerts): string | null {
  if (alerts.alertRequired) {
    return errorMessages.alertRequired;
  }
  if (alerts.alertDate) {
    return errorMessages.alertDate;
  }

  if (alerts.alertEffort) {
    return errorMessages.alertEffort;
  }

  if (alerts.alertTitleLength) {
    return errorMessages.alertTitleLength;
  }

  return null;
}


export const DialogNewTask: React.FC<IDialogNewTaskProps> = ({
                                                               isOpen,
                                                               task,
                                                               handleClose,
                                                               isLoading,
                                                               alerts,
                                                               fieldDate,
                                                               creators,
                                                               assignees,
                                                               handleChangeMenu,
                                                               handleChangeEffort,
                                                               handleSubmit,
                                                               handleChange,
                                                               handleChangeDate,
                                                               tags,
                                                               addNewTag,
                                                               removeTag,
                                                               links,
                                                               addNewLink,
                                                               handleRemovedLink,
                                                               tagsForAutocomplete
                                                             }) => {
  const classes = useStyles();
  const [dateForDisplay, setDateForDisplay] = useState('');

  useEffect(() => {
    const date = fieldDate?.split('T')[0];
    setDateForDisplay(date);
  }, [fieldDate]);

  const [showTagInput, setShowTagInput] = useState(false);
  const [showLinksInput, setShowLinksInput] = useState(false);
  const [effortInForm, setEffortInForm] = useState(task.effort + '');

  useEffect(() => {
    const {id} = task;
    const effort = task.effort.toString();

    if (id && effortInForm === '-1') {
      if (Object.values(ValidTaskEfforts).includes(effort)) {
        setEffortInForm(effort);
      } else setEffortInForm(ValidTaskEfforts.other);
    }
  }, [task, setEffortInForm, effortInForm]);

  if (isLoading) {
    return <LoadingSpinner/>;
  }

  function ShowAlert(): JSX.Element {
    const alertMessage = getAlertMessage(alerts);
    return (
      <div>
        {alertMessage && (
          <Alert variant="outlined" severity="error">
            {alertMessage}
          </Alert>
        )}
      </div>
    );
  }

  function handleNewTag(value: string) {
    addNewTag(value);
    setShowTagInput(false);
  }

  function handleNewLink(value: string) {
    addNewLink(value);
    setShowLinksInput(false);
  }

  function handleKeyPressForTask(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.key === 'Enter') {
      const target = e.target as any;
      handleNewTag(target.value);
    }
  }

  function handleKeyPressForLink(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.key === 'Enter') {
      const target = e.target as any;
      handleNewLink(target.value);
    }
  }

  function handleFormEffortChange(e: SelectChangeEvent<{ value: unknown }> | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const {value} = e.target;
    setEffortInForm(value as string);
    handleChangeEffort(e);
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="sm">
      <DialogTitle id="form-dialog-title">
        {task.id === undefined ? <span>Neue Aufgabe erstellen</span> : <span>{`Aufgabe ${task.id}`}</span>}
      </DialogTitle>
      <DialogContent>
        <TaskFormTags
          tags={tags}
          onClick={() => setShowTagInput(true)}
          showTagInput={showTagInput}
          onBlur={e => handleNewTag(e.target.value)}
          onKeyPress={e => handleKeyPressForTask(e)}
          handleTagRemoval={removeTag}
          addTagFromAutocomplete={v => handleNewTag(v)}
          tagsForAutocomplete={tagsForAutocomplete}
        />
        <FormControl required className={classes.inputElement} fullWidth>
          <FormHelperText>Titel *</FormHelperText>
          <TextField
            variant="standard"
            required={true}
            autoFocus
            margin="dense"
            id="title"
            type="text"
            inputProps={{
              maxLength: 120
            }}
            value={task.title}
            onChange={e => handleChange(e, 'title')}
          />
        </FormControl>

        <div className="row">
          <FormControl className={classes.inputElement}>
            <FormHelperText>To Do Datum *</FormHelperText>
            <TextField
              variant="standard"
              margin="dense"
              id="date"
              type="date"
              required={true}
              value={dateForDisplay}
              onChange={e => handleChangeDate(e, 'tododate')}
            />
          </FormControl>
          <TaskFormEffort classes={classes} effortInForm={effortInForm}
                          handleFormEffortChange={e => handleFormEffortChange(e)}
                          effortInModel={task.effort} handleModelEffortChange={e => handleChangeEffort(e)}/>
        </div>
        <FormControl className={classes.inputElement} fullWidth>
          <FormHelperText>Inhaber</FormHelperText>
          <Select variant="standard" labelId="owner" id="owner" value={task.creatorId}
                  onChange={e => handleChangeMenu(e, 'creatorId')}>
            {creators.map(creator => (
              <MenuItem value={creator.id} key={creator.id}>
                {creator.firstName} {creator.lastName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.inputElement} fullWidth>
          <FormHelperText>Bearbeiter</FormHelperText>
          <Select
            variant="standard"
            labelId="assignee"
            id="assignee"
            value={task.assigneeId}
            onChange={e => handleChangeMenu(e, 'assigneeId')}
          >
            {assignees.map(assignee => (
              <MenuItem value={assignee.id} key={assignee.id}>
                {assignee.firstName} {assignee.lastName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl className={classes.inputElement} fullWidth>
          <FormHelperText>Beschreibung</FormHelperText>
          <TextField
            variant="standard"
            margin="dense"
            id="description"
            type="text"
            multiline
            value={task.description}
            onChange={e => handleChange(e, 'description')}
          />
        </FormControl>

        <TaskFormLinks
          links={links}
          onClick={() => setShowLinksInput(true)}
          showLinkInput={showLinksInput}
          onBlur={e => handleNewLink(e.target.value)}
          onKeyPress={e => handleKeyPressForLink(e)}
          handleLinkRemoval={handleRemovedLink}
        />
        <ShowAlert/>
      </DialogContent>
      <DialogActions>
        <StyledButton onClick={handleClose} color="primary">
          Abbrechen
        </StyledButton>
        <StyledButton className={classes.saveButton} onClick={e => handleSubmit(e)}>
          Speichern
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};
