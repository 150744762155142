import { BoxProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { adjustBrightness } from '../utils/assignColors';
import { serverUrl, useAxios } from '../utils/httpClient';
import Avatar from '../assets/avatar.jpg';
import { IBoardTask } from '../types/tasks';
import { CreateTaskContainer } from '../containers/createTaskContainer';
import { ColumnNames } from '../types/board';

interface IStyleProps {
  isDragging: boolean;
  task: IBoardTask;
  columnTitle: string;
  passedDate: boolean;
}

interface ICardProps {
  isDragging: boolean;
  task: IBoardTask;
  columnTitle: string;
}

const useStyles = makeStyles({
  container: {
    width: '100%',
    boxSizing: 'border-box',
    overflow: 'hidden',
    padding: '0.6em 1.2em',
    marginBottom: '10px',
    backgroundColor: (props: IStyleProps) => (props.isDragging ? '#fcfcfc' : 'white'),
    boxShadow: (props: IStyleProps) =>
      props.passedDate && props.columnTitle !== 'done' ? '2px 3px 2px rgb(230 50 50)' : '1px 1px 1px rgb(9 30 66 / 25%)',
    borderRadius: '6px',
    display: 'flex',
    flexDirection: 'row'
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    '&:first-of-type': {
      flexGrow: 2
    },
    '&:last-of-type': {
      justifyContent: 'space-between',
      alignItems: 'flex-end'
    }
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '5px'
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: '5px'
  },
  taskId: {
    fontSize: '0.8rem',
    color: '#828282'
  },
  title: {
    fontSize: '1.2rem',
    fontWeight: 700,
    textAlign: 'start',
    color: '#727272'
  },
  ownerName: {
    fontSize: '0.9rem',
    backgroundColor: (props: IStyleProps) => props.task.creator.color ?? '#000000',
    color: (props: IStyleProps) => adjustBrightness(props.task.creator.color),
    padding: '0.3em 1.4em 0.3em 0.8em',
    borderRadius: '8px',
    boxShadow: '9px 9px 9px -10px rgb(150 150 150)'
  },
  picture: {
    minWidth: '35px',
    width: '45px',
    height: '45px',
    borderRadius: '50%'
  },
  tagsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    listStyle: 'none',
    paddingLeft: 0
  },
  tag: {
    backgroundColor: '#3F00D5',
    margin: '0 0.5em 0.3em 0',
    padding: '0.3em 0.5em',
    borderRadius: '6px',
    color: '#FFFFFF',
    fontWeight: 400,
    boxShadow: '9px 9px 9px -10px rgb(150 150 150)'
  }
});

export const Card = (props: ICardProps & Omit<BoxProps, keyof IStyleProps>) => {
  const { columnTitle, task, isDragging, ...other } = props;

  let passedDate = false;
  const today = new Date();
  const toDoDate = new Date(task.toDoDate);
  if (today.getTime() > toDoDate.getTime()) {
    passedDate = true;
  }
  if (today.getDay() === toDoDate.getDay() && today.getMonth() === toDoDate.getMonth() && today.getFullYear() === toDoDate.getFullYear()) {
    passedDate = false;
  }

  const styleProps = { isDragging, task, columnTitle, passedDate };
  const classes = useStyles(styleProps);

  // eslint-disable-next-line eqeqeq
  const isDateVisilbe = columnTitle != ColumnNames.today && columnTitle != ColumnNames.tomorrow && columnTitle != ColumnNames.inProgress;
  const isAssigneeMissing = task.assignee.firstName === null;
  const isTagsListVisible = task.tags && task.tags.length !== 0;

  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const handleOpen = () => setIsTaskModalOpen(true);
  const handleClose = () => setIsTaskModalOpen(false);

  function showAvatarForAssignee() {
    return task.assignee.profileImage === '' ? (
      <div style={{ fontSize: 'small' }}>{`${task.assignee.firstName} ${task.assignee.lastName}`}</div>
    ) : (
      <img
        src={`${serverUrl}/images/${task.assignee.profileImage}`}
        alt={`${task.assignee.firstName} ${task.assignee.lastName}`}
        className={classes.picture}
      />
    );
  }

  function showTagsList(tags: string[]) {
    return (
      <ul className={classes.tagsContainer}>
        {tags.map(t => (
          <li className={classes.tag} key={t}>
            {t}
          </li>
        ))}
      </ul>
    );
  }

  function showEffort() {
    const effort = task.effort;

    if (effort < 1) {
      const effortInMinutes = 60 * effort;
      return `${effortInMinutes} min`;
    }
    return `${effort} std`;
  }

  return (
    <>
      <div onClick={handleOpen} className={classes.container}>
        <div className={classes.column}>
          <div className={classes.titleRow}>
            <div className={classes.title}>{task.title}</div>
          </div>
          <div className={classes.row}>
            <div>
              <div className="row" style={{ paddingBottom: '5px' }}>
                <div className={classes.ownerName}>{`${task.creator.firstName} ${task.creator.lastName}`}</div>
              </div>
              {isDateVisilbe && (
                <div className={classes.row}>
                  <div className={classes.taskId}>
                    {task.toDoDate.toString().substring(8, 10)}.{task.toDoDate.toString().substring(5, 7)}.
                    {task.toDoDate.toString().substring(0, 4)}
                  </div>
                </div>
              )}
            </div>
          </div>
          {isTagsListVisible && showTagsList(task.tags as string[])}
        </div>
        <div className={classes.column}>
          <div className={classes.taskId}>{showEffort()}</div>
          {isAssigneeMissing ? <img src={Avatar} alt={`no assignee`} className={classes.picture} /> : showAvatarForAssignee()}
        </div>
      </div>
      {isTaskModalOpen && <CreateTaskContainer taskId={task.id} isOpen={isTaskModalOpen} columnId={columnTitle} closeSelf={handleClose} />}
    </>
  );
};

export default Card;
