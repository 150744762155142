import React, {FocusEvent, KeyboardEvent, useEffect, useState} from 'react';
import AddIcon from '@mui/icons-material/Add';
import {Autocomplete, Chip, MenuItem, Select, TextField} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import {Theme} from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import {TagsForAutocomplete} from '../types/tags';
import {authenticationService} from '../services/AuthenticationService';

interface ITaskFormTagsParams {
    tags: string[];
    onClick: () => void;
    showTagInput: boolean;
    onBlur: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onKeyPress: (e: KeyboardEvent<HTMLDivElement>) => void;
    handleTagRemoval: (tag: string) => void;
    tagsForAutocomplete: TagsForAutocomplete | undefined;
    addTagFromAutocomplete: (tag: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        row: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center'
        },
        list: {
            listStyle: 'none',
            paddingLeft: 0
        },
        listItem: {
            marginTop: 0
        },
        chip: {
            margin: theme.spacing(0.5)
        }
    })
);

export const TaskFormTags = (props: ITaskFormTagsParams) => {
    const classes = useStyles();
    const [isAdmin, setIsAdmin] = useState<boolean>(false);

    useEffect(() => {
        let isMounted = true;

        const checkIsAdmin = async () => {
            const isAdmin = await authenticationService.IsAdmin();

            if (isMounted) {
                setIsAdmin(isAdmin);
            }
        };

        checkIsAdmin();

        return () => {
            isMounted = false;
        }
    }, []);

    function renderTagSelector() {
        if (isAdmin)
            return (
                <Autocomplete
                    disablePortal
                    size="small"
                    options={props.tagsForAutocomplete ?? []}
                    onChange={(_, newValue) => {
                        if (newValue !== null) props.addTagFromAutocomplete(newValue.label);
                    }}
                    renderInput={params => <TextField autoFocus variant="filled" {...params} onBlur={props.onBlur}
                                                      onKeyPress={props.onKeyPress}/>}
                />
            );
        else
            return (
                <Select
                    variant="standard"
                    labelId="effort"
                    id="effort"
                    sx={{width: '20%'}}
                    onChange={e => props.addTagFromAutocomplete(e.target.value as string)}
                >
                    {props.tagsForAutocomplete?.map(tag => (
                        <MenuItem value={tag.label} key={tag.label}>
                            {tag.label}
                        </MenuItem>
                    ))}
                </Select>
            );
    }

    return (
        <div className={classes.row}>
            <ul className={`${classes.list} ${classes.row}`}>
                {props.tags.map((tag, idx) => (
                    <li key={tag} className={classes.listItem}>
                        <Chip label={tag} onDelete={_ => props.handleTagRemoval(tag)} className={classes.chip}/>
                    </li>
                ))}
            </ul>
            <AddIcon onClick={props.onClick}/>
            {props.showTagInput && renderTagSelector()}
        </div>
    );
}
