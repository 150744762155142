import React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import Login from '../components/login';
import {ProfileCheck} from '../components/profileCheck';
import {AdminRoute, PrivateRoute} from './utils';
import {BoardContainer} from '../containers/boardContainer';
import {DoneContainer} from '../containers/doneContainer';
import {BacklogContainer} from '../containers/backlogContainer';
import {ProfileUpdateContainer} from '../containers/profileUpdateContainer';
import {ProfileInitContainer} from '../containers/profileInitContainer';
import UserManagement from '../components/userManagement';
import RegisterUser from '../components/registerUser';
import EditUser from '../components/editUser';
import DeleteUser from '../components/deleteUser';

export const AppRouter = () => {
    return (
        <Router>
            <Switch>
                <Redirect from="/" exact={true} to="/board"/>
                <PrivateRoute path="/board" component={BoardContainer}/>
                <PrivateRoute path="/backlog" component={BacklogContainer}/>
                <PrivateRoute path="/done" component={DoneContainer}/>
                <PrivateRoute path="/profile-settings" component={ProfileInitContainer}/>
                <PrivateRoute path="/check-profile" exact={true} component={ProfileCheck}/>
                <PrivateRoute path="/profile-settings-update" component={ProfileUpdateContainer}/>
                <AdminRoute path="/user-management" component={UserManagement}/>
                <AdminRoute path="/user-add" component={RegisterUser}/>
                <AdminRoute path="/user-edit" component={EditUser}/>
                <AdminRoute path="/user-delete" component={DeleteUser}/>
                <Route path="/login" component={Login}/>
            </Switch>
        </Router>
    );
};
