import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {ProfileSettings} from '../components/profileSettings';
import {useAxios} from '../utils/httpClient';

export const ProfileUpdateContainer = () => {
    const [selectedColor, setSelectedColor] = useState('');
    const [encodedImage, setEncodedImage] = useState('');
    const httpClient = useAxios();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        httpClient.get('users/profile/me').then(r => {
            const {color, image} = r.data;
            setSelectedColor(color);
            setIsLoading(false);
            setEncodedImage('data:image/png;base64, ' + image);
        });
    }, []);

    const handleSettingsUpload = () => {
        httpClient
            .put('users/profile/media', {
                image: encodedImage.split(',')[1],
                color: selectedColor
            })
            .then(() => history.push('/board'));
    };

    if (isLoading) return <h3>Loading...</h3>

    return (
        <>
            <ProfileSettings
                handleSettingsUpload={handleSettingsUpload}
                selectedColor={selectedColor}
                handleColorSelection={setSelectedColor}
                encodedImage={encodedImage}
                handleEncodedImageSet={setEncodedImage}
            />
        </>
    );
};
