import { ITasks, IBoardSection } from './board';
import { IBoardTask } from './tasks';

export interface IInitialData {
  tasks: ITasks;
  columns: {
    [columnId: string]: IBoardSection;
  };
  columnOrder: string[];
}

export enum ColumnIds {
  today = 'today',
  tomorrow = 'tomorrow',
  inProgress = 'inProgress',
  review = 'review',
  toBeFinished = 'toBeFinished',
  done = 'done'
}

export interface IMainBoard {
  today: IBoardTask[];
  tomorrow: IBoardTask[];
  inProgress: IBoardTask[];
  review: IBoardTask[];
  toBeFinished: IBoardTask[];
}

interface IBoardTaskDto {
  id: string;
  type: number;
  tasks: IBoardTask[];
}

export interface IMainBoardDto {
  [key: string]: IBoardTaskDto;
}
