import {Alert, Container, CssBaseline, FormControl, FormHelperText, MenuItem, Select, Typography} from "@mui/material";
import React, {FormEvent, useEffect, useState} from "react";
import {authenticationService} from "../services/AuthenticationService";
import {IUser} from "../types/user";
import {NavBar} from "./navbar";
import {StyledButton} from "./styledButton";
import {Redirect} from "react-router-dom";
import {useUserManagementStyles} from "../utils/stylingHelper";

export const DeleteUser = () => {
    const classes = useUserManagementStyles();
    const [status, setStatus] = useState<string>("loading");
    const [users, setUsers] = useState<IUser[]>([]);
    const [selectedUser, setSelectedUser] = useState<IUser>({id: "", lastName: "", firstName: "", email: ""});
    const [errors, setErrors] = useState<string[]>([]);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setErrors([]);
        setStatus("loading");

        if (!selectedUser.id)
            return;

        authenticationService.Delete(selectedUser.id).then(response => {
            if (response.succeeded) {
                setStatus("success");
            } else {
                setStatus("");
                setErrors(response.errors);
            }
        })
    }

    const handleAbort = () => {
        setStatus("abort");
    }

    const handleUserChange = (userId: string) => {
        const userFound = users.find(u => u.id === userId) || {id: '', firstName: '', lastName: '', email: ''} as IUser;
        setSelectedUser(userFound);
    }

    const renderSelectUser = () => {
        return (
            <FormControl required fullWidth className={classes.formControl}>
                <FormHelperText>Benutzer *</FormHelperText>
                <Select variant="standard"
                        labelId="selectedUser"
                        id="selectedUser"
                        value={selectedUser.id}
                        onChange={e => handleUserChange(e.target.value)}>
                    {users.map((user) => (
                        <MenuItem value={user.id}
                                  key={user.id}>{user.firstName} {user.lastName} ({user.userName})</MenuItem>
                    ))}
                </Select>
            </FormControl>
        )
    }

    const renderAlert = () => {
        if (!errors.length) {
            return;
        }

        return (
            <Alert severity="error" style={{marginTop: "3rem"}}>
                {errors.length === 1 ?
                    errors[0]
                    :
                    <ul>
                        {errors.map((error, index) => <li key={index}>{error}</li>)}
                    </ul>
                }

            </Alert>
        )
    }

    useEffect((): void => {
        authenticationService.GetAllUsers(true).then(userResponse => {
            setUsers(userResponse.length ? userResponse : []);
            setStatus("");
        });
    }, []);

    if (status === "loading") {
        return (<div>loading...</div>);
    }

    if (status === "success") {
        return <Redirect push to={{
            pathname: "/user-management",
            state: {successMsg: "Benutzer wurde gelöscht"}
        }}/>
    }

    if (status === "abort") {
        return <Redirect push to="/user-management"/>
    }

    return (
        <React.Fragment>
            <NavBar basicHeaderOnly={true}></NavBar>
            <CssBaseline/>
            <Container maxWidth="sm">
                <Typography variant="h5" component="h1" className={classes.title}>Benutzer löschen</Typography>
                {renderAlert()}
                <form onSubmit={handleSubmit} autoComplete="off">
                    {renderSelectUser()}
                    <StyledButton
                        type="button"
                        className={classes.secondaryButton}
                        onClick={handleAbort}
                        variant="outlined">
                        Abbrechen
                    </StyledButton>

                    <StyledButton
                        type="submit"
                        className={classes.primaryButton}>
                        Löschen
                    </StyledButton>
                </form>
            </Container>
        </React.Fragment>
    );
}

export default DeleteUser;