import { createTheme, adaptV4Theme } from '@mui/material/styles';

const theme = createTheme(adaptV4Theme({
  palette: {
    primary: {
      main: '#A5BC19'
    },
    secondary: {
      main: '#7A8A12'
    }
  }
}));

export default theme;
