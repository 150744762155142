import React, {useState} from 'react';
import {Alert, Container, CssBaseline, Typography} from "@mui/material";
import {NavBar} from './navbar';
import {Redirect} from "react-router-dom";
import {StyledButton} from './styledButton';
import {useUserManagementStyles} from '../utils/stylingHelper'

export const UserManagement = (props: any) => {
    const classes = useUserManagementStyles();
    const [status, setStatus] = useState<string>("");

    const handleNewUser = () => {
        setStatus("registerNewUser");
    }

    const handleEditUser = () => {
        setStatus("editUser");
    }

    const handleDeleteUser = () => {
        setStatus("deleteUser");
    }

    const handleAbort = () => {
        setStatus("abort");
    }

    const renderSuccessAlert = () => {
        if (!props?.location?.state?.successMsg)
            return;

        return (<Alert severity="success" style={{marginTop: "3rem"}}>{props.location.state.successMsg}</Alert>);
    }

    if (status === "abort") {
        return <Redirect to="/"/>
    }

    if (status === "registerNewUser") {
        return <Redirect to="/user-add"></Redirect>
    }

    if (status === "editUser") {
        return <Redirect to="/user-edit"></Redirect>
    }

    if (status === "deleteUser") {
        return <Redirect push to="/user-delete"></Redirect>
    }

    return (
        <React.Fragment>
            <NavBar basicHeaderOnly={true}></NavBar>
            <CssBaseline/>
            <Container maxWidth="sm">
                <Typography variant="h5" component="h1" className={classes.title}>Adminbereich</Typography>
                {renderSuccessAlert()}
                <div className={classes.buttonWrapper}>
                    <StyledButton
                        className={classes.navButton}
                        onClick={handleNewUser}>Neuen Benutzer anlegen</StyledButton>
                    <StyledButton
                        className={classes.navButton}
                        onClick={handleEditUser}>Benutzer bearbeiten</StyledButton>
                    <StyledButton
                        className={classes.navButton}
                        onClick={handleDeleteUser}>Benutzer entfernen</StyledButton>
                    <StyledButton
                        className={classes.secondaryNavButton}
                        onClick={handleAbort}
                        variant="outlined">Zurück</StyledButton>
                </div>
            </Container>
        </React.Fragment>
    );
}

export default UserManagement;