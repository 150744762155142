import {HubConnection, HubConnectionBuilder, LogLevel} from '@microsoft/signalr';
import {useEffect, useState} from 'react';

function buildConnection() {
    return new HubConnectionBuilder()
        .withUrl(`${process.env.REACT_APP_API_URL}/api/v1/hubs/notifications`)
        .configureLogging(LogLevel.Warning)
        .withAutomaticReconnect()
        .build();
}

export function useRealtimeConnection(): HubConnection | null {
    const [connection, setConnection] = useState<HubConnection | null>(null);

    useEffect(() => {
        connection?.stop();

        const newConnection = buildConnection();
        setConnection(newConnection);

        startConnection(newConnection);
        newConnection.onclose(() => startConnection(newConnection));
    }, []);

    return connection;
}

async function startConnection(newConnection: HubConnection) {
    try {
        await newConnection.start();
        console.log('SignalR Connected.');
    } catch (err) {
        console.error(err);
        setTimeout(() => startConnection(newConnection), 5000);
    }
}
