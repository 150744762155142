export enum RoleEnum {
    admin = "Admin",
    standard = "Standard",
}

export class Role {
    key: RoleEnum;
    name: string

    constructor(key: RoleEnum, name: string) {
        this.key = key;
        this.name = name;
    }
}

export function getUserRoles(): Role[] {
    return [
        new Role(RoleEnum.standard, "Standard"),
        new Role(RoleEnum.admin, "Admin")
    ];
}