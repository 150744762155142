import React, {useEffect, useState} from 'react';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MoreIcon from '@mui/icons-material/MoreVert';
import Logo from '../assets/logo.svg';
import {Redirect} from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {StyledButton} from './styledButton';
import {FilterTasksContainer, IFilterTasksContainerProps} from '../containers/filterTasksContainer';
import {authenticationService} from '../services/AuthenticationService';
import {useStore} from '../state/store';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grow: {
            flexGrow: 1
        },
        menuButton: {
            marginRight: theme.spacing(2)
        },
        logo: {
            maxWidth: 160,
            paddingTop: '1em',
            paddingBottom: '1em'
        },
        sectionDesktop: {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'flex'
            }
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                display: 'none'
            }
        },
        fullScreenButton: {
            '&:hover': {
                backgroundColor: 'inherit'
            }
        }
    })
);

interface INavbarProps {
    filteringData?: IFilterTasksContainerProps;
    basicHeaderOnly?: boolean
}

export const NavBar: React.FC<INavbarProps> = ({filteringData, basicHeaderOnly = false}) => {
    const classes = useStyles();
    const user = useStore(state => state.user);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null);
    const [userName, setUserName] = useState('');
    const [hasLoggedOut, setHasLoggedOut] = useState(false);
    const [userIsAdmin, setUserIsAdmin] = useState(false);
    const [openUserManagement, setOpenUserManagement] = useState(false);
    const [openProfileSettings, setOpenProfileSettings] = useState(false);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleOpenProfileSettings = (event: React.MouseEvent<HTMLElement>) => {
        setOpenProfileSettings(true);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleLogout = (): void => {
        authenticationService.Logout().then(() => {
            setHasLoggedOut(true);
        });
        handleMenuClose();
    };

    const handleUserManagement = (): void => {
        setOpenUserManagement(true);
        handleMenuClose();
    };

    const handleFullScreen = () => {
        var elem = document.getElementById('root');
        if (elem != null) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            }
        }
    };

    useEffect((): void => {
        authenticationService.IsAdmin().then(isAdmin => {
            setUserIsAdmin(isAdmin);
        })
    }, []);

    useEffect(() => {
        if ((user?.firstName?.trim() ?? "") !== "" || (user?.lastName?.trim() ?? "") !== "") {
            setUserName(`${user.firstName} ${user.lastName}`);
        }
    }, []);
    
    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            id={menuId}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleOpenProfileSettings}>Profil</MenuItem>
            {userIsAdmin ? <MenuItem onClick={handleUserManagement}>Benutzerverwaltung</MenuItem> : ''}
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                    size="large">
                    <AccountCircle style={{color: 'white'}}/>
                </IconButton>
                <p>Profil</p>
            </MenuItem>
        </Menu>
    );

    if (openUserManagement) {
        return <Redirect to="/user-management"/>
    }

    if (openProfileSettings) {
        return <Redirect from="/" to="/profile-settings-update"/>
    }

    if (hasLoggedOut) {
        return <Redirect to="/login"/>
    }

    if (basicHeaderOnly) {
        return (
            <div className={classes.grow}>
                <AppBar position="static" style={{boxShadow: 'none'}}>
                    <Toolbar>
                        <Toolbar>
                            <img src={Logo} alt="logo" className={classes.logo}/>
                        </Toolbar>
                    </Toolbar>
                </AppBar>
            </div>
        )
    }

    return (
        <div className={classes.grow}>
            <AppBar position="static" style={{boxShadow: 'none'}}>
                <Toolbar>
                    <Toolbar>
                        <img src={Logo} alt="logo" className={classes.logo}/>
                    </Toolbar>
                    <div className={classes.grow}>
                        {filteringData && (
                            <FilterTasksContainer
                                name={filteringData.name}
                                setData={filteringData.setData}
                                setAreAllTasksVisible={filteringData.setAreAllTasksVisible}
                            />
                        )}
                    </div>
                    <MenuItem className={classes.fullScreenButton}>
                        <StyledButton onClick={handleFullScreen} style={{color: 'white', fontSize: '1em'}}>
                            <VisibilityIcon/> &nbsp; Vollbildmodus
                        </StyledButton>
                    </MenuItem>
                    <MenuItem style={{color: 'white', fontSize: '1.2em'}}>{userName}</MenuItem>
                    <div className={classes.sectionDesktop}>
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                            size="large">
                            <AccountCircle style={{color: 'white'}}/>
                        </IconButton>
                    </div>
                    <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                            size="large">
                            <MoreIcon/>
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
        </div>
    );
};
