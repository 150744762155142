import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { IBoardSection } from '../types/board';
import { DroppableArea } from './dropArea';
import { StateViewSelector } from './stateViewSelector';
import { NavBar } from './navbar';

interface IBoardLayoutProps {
  isDropDisabled?: boolean;
  section?: IBoardSection;
  dropName?: string;
  name: string;
  setData: any;
  setAreAllTasksVisible?: any;
}

const useStyles = makeStyles({
  globalRoot: {
    maxHeight: '100vh',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column'
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#F6F6F6',
    height: '100%',
    overflowY: 'hidden',
    width: '100vw'
  },
  left: {
    display: 'flex',
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center'
  },
  right: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0.5em 3em',
    boxSizing: 'border-box',
    width: 'calc(100vw - 60px)'
  },
  columnSmall: {
    flexDirection: 'row',
    maxWidth: '100%',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    justifyContent: 'flex-end',
    alignContent: 'flex-end',
    marginLeft: 'auto',
    padding: '0.5em',
    backgroundColor: '#F6F6F6',
    width: '50%',
    display: 'flex'
  }
});

export const BoardLayout: React.FC<IBoardLayoutProps> = ({
  children,
  section,
  isDropDisabled = true,
  dropName = '',
  name,
  setData,
  setAreAllTasksVisible = false
}) => {
  const classes = useStyles();

  return (
    <div className={classes.globalRoot}>
      <NavBar filteringData={{ name, setData, setAreAllTasksVisible }} />
      <div className={classes.container}>
        <div className={classes.left}>
          <StateViewSelector />
        </div>
        <div className={classes.right}>
          <div className={classes.columnSmall}>
            {section && <DroppableArea isDropDisabled={isDropDisabled} section={section} name={dropName} />}
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};
