import { TaskStatus, IBoardSection } from '../../types/board';
import { WeekSectionIds, IBacklog } from '../../types/backlog';

export function createWeekSectionsForBacklog(backlog: IBacklog): IBoardSection[] {
  return [
    {
      id: WeekSectionIds.currentWeek,
      title: 'Diese Woche',
      tasks: backlog?.currentWeek,
      type: TaskStatus.backlog,
      isDropDisabled: (_: string) => true
    },
    {
      id: WeekSectionIds.nextWeek,
      title: 'Nächste Woche',
      tasks: backlog?.nextWeek,
      type: TaskStatus.backlog,
      isDropDisabled: (_: string) => true
    },
    {
      id: WeekSectionIds.restWeeksTasks,
      title: 'Später',
      tasks: backlog?.restWeeksTasks,
      type: TaskStatus.backlog,
      isDropDisabled: (_: string) => true
    },
    {
      id: WeekSectionIds.inProgress,
      title: 'In Arbeit',
      tasks: [],
      type: TaskStatus.inProgress,
      isDropDisabled: (source: string) =>
        source !== WeekSectionIds.currentWeek && source !== WeekSectionIds.nextWeek && source !== WeekSectionIds.restWeeksTasks
    }
  ];
}
