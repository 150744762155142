import React from 'react';
import { Container, Paper, styled, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { adjustBrightness } from '../utils/assignColors';

interface IStyleProps {
  color: string;
}

const useStyles = makeStyles({
  cell: {
    backgroundColor: (props: IStyleProps) => props.color,
    width: '30px',
    height: '30px',
    border: 'none',
    margin: '1px 2px'
  }
});

const InnerContainer = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(2),
  flexDirection: 'column',
  overflow: 'hidden',
  padding: theme.spacing(2),
  textAlign: 'center'
}));

interface IPickColorProps {
  colors: string[];
  selectColor: Function;
  selectedColor: string;
}

export const PickColor: React.FC<IPickColorProps> = ({ colors, selectColor, selectedColor }) => {
  const Cell = (props: IStyleProps) => {
    const classes = useStyles(props);
    return <button className={classes.cell} onClick={e => selectColor(e, props.color)}></button>;
  };

  const ColorPalette = () => {
    return (
      <div>
        {colors.map((color, index) => (
          <Cell key={index} color={color} />
        ))}
      </div>
    );
  };

  return (
    <Container maxWidth="sm">
      <InnerContainer>
        <Typography variant="h6" component="h1" style={{ marginBottom: '27px' }}>
          Farbe des Namensschilds auswählen
        </Typography>
        <ColorPalette />
        <div style={{ margin: '20px' }}>
          Ihr Name wird wie folgt angezeigt: &nbsp;&nbsp;
          <span
            style={{
              backgroundColor: selectedColor,
              color: adjustBrightness(selectedColor),
              width: '50px'
            }}
          >
            name
          </span>
        </div>
      </InnerContainer>
    </Container>
  );
};
