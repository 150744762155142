import React, { useEffect, useState } from 'react';
import { useAxios } from '../utils/httpClient';
import { useStore } from '../state/store';
import { IBacklog } from '../types/backlog';
import { IBoardSection } from '../types/board';
import { createWeekSectionsForBacklog } from '../utils/mappers/backlogToWeekSections';
import { DragDropContext, DraggableLocation, DragStart, DropResult } from 'react-beautiful-dnd';
import { useRealtimeClient } from '../state/realtime.context';
import { Backlog } from '../components/backlog';
import { AxiosInstance } from 'axios';
import { Column } from '../components/column';

function moveCardToNewSection(
  originSection: IBoardSection | undefined,
  source: DraggableLocation,
  destinationSection: IBoardSection | undefined
) {
  const task = originSection?.tasks[source.index];
  originSection?.tasks.splice(source.index, 1);

  if (task && destinationSection) {
    destinationSection.tasks.push(task);
  }
}

function isDestinationSameAsSource(destination: DraggableLocation, source: DraggableLocation): boolean {
  return destination.droppableId === source.droppableId && destination.index === source.index;
}

function getTasks(httpClient: AxiosInstance, setBacklog: (backlog: IBacklog) => void, ownerId?: string, assigneeId?: string) {
  httpClient
    .get<IBacklog>('tasks/backlog', {
      params: {
        CreatorId: ownerId,
        AssigneeId: assigneeId
      }
    })
    .then(r => {
      setBacklog(r.data);
    })
    .catch(console.error);
}

export const BacklogContainer = () => {
  const httpClient = useAxios();
  const setBacklog = useStore(state => state.setBacklog);
  const setIsDropAreaVisible = useStore(state => state.setIsDropAreaVisible);
  const [dragOrigin, setDragOrigin] = useState('');
  const realtimeConnection = useRealtimeClient();
  const { ownerId, assigneeId } = useStore(state => ({ ownerId: state.ownerId, assigneeId: state.assigneeId }));

  useEffect(() => {
    getTasks(httpClient, setBacklog);

    const handleUpdate = () => getTasks(httpClient, setBacklog, ownerId, assigneeId);

    realtimeConnection.onUpdate(handleUpdate);

    return () => realtimeConnection.clear();
  }, [ownerId, assigneeId]);

  const backlog = useStore(state => state.backlog);

  const sections: IBoardSection[] = createWeekSectionsForBacklog(backlog);

  const onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result;

    setIsDropAreaVisible(false);

    if (!destination || isDestinationSameAsSource(destination, source)) return;

    const originSection = sections.find(c => c.id == source.droppableId);
    const destinationSection = sections.find(c => c.id == destination.droppableId);

    // Reordering in list
    if (!destinationSection || originSection?.id === destinationSection?.id) {
      return;
    }

    httpClient
      .patch(`tasks/${draggableId}`, {
        inputState: destinationSection.type
      })
      .catch(err => console.error(err));

    moveCardToNewSection(originSection, source, destinationSection);
  };

  const onDragStart = (start: DragStart) => {
    const origin = start.source.droppableId;
    setDragOrigin(origin);
    setIsDropAreaVisible(true);
  };

  const sectionElements = sections.slice(0, sections.length - 1).map(section => {
    return <Column key={section.id} column={section} isDropDisabled={true} />;
  });

  const lastSection = sections[sections.length - 1];
  const isInProgressDisabled = lastSection.isDropDisabled(dragOrigin);

  return (
    <>
      <DragDropContext onDragEnd={r => onDragEnd(r)} onDragStart={r => onDragStart(r)}>
        <Backlog
          isInProgressDisabled={isInProgressDisabled}
          lastSection={lastSection}
          totalEffort={backlog.totalEffort}
          sectionElements={sectionElements}
          setData={setBacklog}
        />
      </DragDropContext>
    </>
  );
};
