import axios from 'axios';
import {useStore} from '../state/store';
import {IUser, UserModel, UserViewModel} from '../types/user';
import {useAxios} from '../utils/httpClient';


const useLogin = () => {
    const httpClient = useAxios(true);
    const setUser = useStore(state => state.setUser);

    const login = async (username: string, password: string): Promise<LoginResponse> => {
        try {
            const loginDto: LoginDto = {username: username, password: password};
            const res = await httpClient.post('authentication/login', loginDto);
            const response = (res.data as LoginResponse);
            const userModel = response.userViewModel;

            setUser({
                id: userModel?.id ?? "",
                firstName: userModel?.firstName ?? "",
                lastName: userModel?.lastName ?? "",
            });

            return response;
        } catch (err: any) {
            // bad request with response (400)
            if (err.response && err.response.status === 400) {
                return {
                    succeeded: false,
                    errors: err.response.data.errors || ["Ungültige Anfragedaten."]
                };
            }

            const response = {
                succeeded: false,
                errors: ["Während des Prozesses ist ein unbekannter Fehler aufgetreten"],
                userViewModel: undefined
            } as LoginResponse;
            return response;
        }
    }

    return login;
}

const useIsLoggedIn = () => {
    const httpClient = useAxios(true);
    const setUser = useStore(state => state.setUser);

    const isLoggedIn = async (): Promise<IsLoggedInResponse> => {
        try {
            const res = await httpClient.get('authentication/isLoggedIn');
            const response = res?.data ?? {isLoggedIn: false} as IsLoggedInResponse;
            const userModel = response?.userViewModel;

            setUser({
                id: userModel?.id ?? "",
                firstName: userModel?.firstName ?? "",
                lastName: userModel?.lastName ?? "",
            });

            return response;
        } catch (err: any) {
            return {isLoggedIn: false} as IsLoggedInResponse;
        }
    }

    return isLoggedIn;
}

const useRegister = () => {
    const httpClient = useAxios(true);

    const register = async (newUser: UserModel): Promise<BaseResponse> => {
        try {
            const res = await httpClient.post('authentication/register', newUser);
            const response = (res.data as BaseResponse);
            return response;
        } catch (err: any) {
            // bad request with response (400)
            if (err.response && err.response.status === 400) {
                return {
                    succeeded: false,
                    errors: err.response.data.errors || ["Ungültige Anfragedaten."]
                };
            }
            return {
                succeeded: false,
                errors: ["Während des Prozesses ist ein unbekannter Fehler aufgetreten"]
            };
        }
    }

    return register;
}

const useEdit = () => {
    const httpClient = useAxios(true);

    const edit = async (user: IUser): Promise<BaseResponse> => {
        try {
            const res = await httpClient.patch('/authentication/edit', user);
            const response = (res.data as BaseResponse);
            return response;
        } catch (err: any) {
            // bad request with response (400)
            if (err.response && err.response.status === 400) {
                return {
                    succeeded: false,
                    errors: err.response.data.errors || ["Ungültige Anfragedaten."]
                };
            }
            return {
                succeeded: false,
                errors: ["Während des Prozesses ist ein unbekannter Fehler aufgetreten"]
            };
        }
    }

    return edit;
}

const Delete = async (userId: string): Promise<BaseResponse> => {
    const httpClient = useAxios(true);

    try {
        const res = await httpClient.delete(`authentication/delete/${userId}`);
        const response = (res.data as BaseResponse);
        return response;
    } catch (err: any) {
        // bad request with response (400)
        if (err.response && err.response.status === 400) {
            return {
                succeeded: false,
                errors: err.response.data.errors || ["Ungültige Anfragedaten."]
            };
        }
        return {
            succeeded: false,
            errors: ["Während des Prozesses ist ein unbekannter Fehler aufgetreten"]
        };
    }
}

const ChangePassword = async (oldPassword: string, newPassword: string, newPasswordConfirm: string): Promise<BaseResponse> => {
    const httpClient = useAxios(true);

    try {
        const dto = {
            oldPassword: oldPassword,
            newPassword: newPassword,
            newPasswordConfirm: newPasswordConfirm
        };
        const res = await httpClient.post("/authentication/user/change-password", dto);
        const response = (res.data as BaseResponse);
        return response;
    } catch (err: any) {
        // bad request with response (400)
        if (err.response && err.response.status === 400) {
            return {
                succeeded: false,
                errors: err.response.data.errors || ["Ungültige Anfragedaten."]
            };
        }
        return {
            succeeded: false,
            errors: ["Während des Prozesses ist ein unbekannter Fehler aufgetreten"]
        };
    }
}

const IsAdmin = async (): Promise<boolean> => {
    const httpClient = useAxios(true);
    const res = await httpClient.get('authentication/isAdmin');
    return res?.data ?? false;
}

const GetAllUsers = async (excludeSelf: boolean): Promise<IUser[]> => {
    const httpClient = useAxios(true);
    const res = await httpClient.post('/authentication/users/getall', excludeSelf);
    return res?.data ?? [];
}

const Logout = async (): Promise<void> => {
    const httpClient = useAxios(true);

    try {
        await httpClient.post('authentication/logout');
    } catch (err) {
        console.error(err);
    }
}

// define public methods
export const authenticationService = {
    useLogin,
    useRegister,
    useEdit,
    useIsLoggedIn,
    Delete,
    ChangePassword,
    GetAllUsers,
    Logout,
    confirmEmail,
    confirmPassword,
    IsAdmin
};

function confirmEmail(o: any) {
    return axios.post(`authentication/confirmemail`, o);
}

function confirmPassword(o: any) {
    return axios.post(`authentication/confirmpassword`, o);
}

interface LoginDto {
    username: string;
    password: string;
}

interface LoginResponse extends BaseResponse {
    userViewModel?: UserViewModel;
}

interface IsLoggedInResponse {
    isLoggedIn: boolean;
    userViewModel?: UserViewModel
}

interface BaseResponse {
    succeeded: boolean;
    errors: string[];
}