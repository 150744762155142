import React from 'react';
import { Avatar, Box, Button, Container, Paper, styled, Theme, Typography } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    large: {
      width: theme.spacing(18),
      height: theme.spacing(18),
      margin: '0 auto'
    },
    imageContainer: {
      marginTop: theme.spacing(2),
      width: theme.spacing(24),
      height: theme.spacing(24),
      margin: '0 auto'
    }
  })
);

const InnerContainer = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(2),
  flexDirection: 'column',
  overflow: 'hidden',
  padding: theme.spacing(2),
  textAlign: 'center'
}));

interface IUploadAvatarProps {
  handleImageSelect: Function;
  encodedImage: string;
}

export const UploadAvatar: React.FC<IUploadAvatarProps> = ({ handleImageSelect, encodedImage }) => {
  const classes = useStyles();

  return (
    <Container maxWidth="sm">
      <InnerContainer>
        <Typography variant="h6" component="h1">
          Bitte laden Sie Ihr Profilbild hoch
        </Typography>
        <Button
          variant="contained"
          color="primary"
          component="label"
          style={{ marginTop: '2em', textTransform: 'none', color: 'white' }}
          disableElevation
        >
          Datei hochladen
          <input type="file" hidden onChange={e => handleImageSelect(e.target.files)} accept=".jpg,.jpeg,.png" />
        </Button>
        <Box className={classes.imageContainer}>
          {encodedImage !== '' && <Avatar alt="Profile Image" src={encodedImage} className={classes.large} />}
        </Box>
      </InnerContainer>
    </Container>
  );
};
