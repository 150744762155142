import React from 'react';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Link, useLocation } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BookIcon from '@mui/icons-material/Book';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { NewTask } from './newTask';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '0',
      padding: '0',
      flexGrow: 1,
      border: 0,
      display: 'flex',
      flexDirection: 'column',
      width: '60px'
    },
    tab: {
      display: 'flex',
      justifyContent: 'center',
      padding: '5px',
      margin: '10px 0 10px 0',
      fontSize: '2em',
      fontWeight: 'bold',
      background: 'white',
      color: '#eee'
    },
    active: {
      background: 'white',
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.secondary.main
      }
    },
    link: {
      background: 'white',
      color: '#aaa',
      '&:hover': {
        color: theme.palette.primary.main
      }
    },
    newTask: {}
  })
);

export const StateViewSelector = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  return (
    <div className={classes.root}>
      <div className={classes.newTask}>
        <NewTask />
      </div>
      <div className={classes.tab}>
        <Link style={{ textDecoration: 'none' }} className={pathname !== '/backlog' ? classes.link : classes.active} to="/backlog">
          <BookIcon />
        </Link>
      </div>
      <div className={classes.tab}>
        <Link style={{ textDecoration: 'none' }} className={pathname !== '/board' ? classes.link : classes.active} to="/board">
          <DashboardIcon />
        </Link>
      </div>
      <div className={classes.tab}>
        <Link style={{ textDecoration: 'none' }} className={pathname !== '/done' ? classes.link : classes.active} to="/done">
          <AssignmentTurnedInIcon />
        </Link>
      </div>
    </div>
  );
};
