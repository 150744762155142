import {
    Alert,
    Container,
    CssBaseline,
    FormControl,
    FormHelperText,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import React, {FormEvent, useEffect, useState} from "react";
import {NavBar} from "./navbar";
import {authenticationService} from "../services/AuthenticationService";
import {Redirect} from "react-router-dom";
import {IUser} from "../types/user";
import {StyledButton} from "./styledButton";
import {getUserRoles} from "../types/role";
import {useUserManagementStyles} from "../utils/stylingHelper";

export const EditUser = () => {
    const classes = useUserManagementStyles();
    const edit = authenticationService.useEdit();
    const [status, setStatus] = useState<string>("loading");
    const [users, setUsers] = useState<IUser[]>([]);
    const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
    const [userName, setUserName] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [role, setRole] = useState<string>("");
    const [errors, setErrors] = useState<string[]>([]);

    // submit event
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setErrors([]);
        setStatus("loading");
        const userDto: IUser = {
            id: selectedUser?.id,
            email: email,
            userName: userName,
            password: newPassword,
            passwordConfirm: newPasswordConfirm,
            firstName: firstName,
            lastName: lastName,
            role: role
        }

        edit(userDto).then(response => {
            if (response.succeeded) {
                setStatus("success");
            } else {
                setStatus("");
                setErrors(response.errors);
            }
        })
    }

    const handleAbort = () => {
        setStatus("abort");
    }

    const handleUserChange = (userId: string) => {
        const userFound = users.find(u => u.id === userId);
        if (userFound) {
            setSelectedUser(userFound);
            setUserName(userFound.userName ?? "");
            setEmail(userFound.email ?? "");
            setFirstName(userFound.firstName ?? "");
            setLastName(userFound.lastName ?? "");
            setRole(userFound.role ?? "");
        }
    }

    const handleRoleChange = (role: string) => {
        setRole(role);
    }

    const renderSelectUser = () => {
        if (!users.length) {
            return;
        }

        return (
            <FormControl required fullWidth className={classes.formControl}>
                <FormHelperText>Benutzer *</FormHelperText>
                <Select variant="standard"
                        labelId="selectedUser"
                        id="selectedUser"
                        value={selectedUser?.id}
                        onChange={e => handleUserChange(e.target.value)}>
                    {users.map((user) => (
                        <MenuItem value={user.id}
                                  key={user.id}>{user.firstName} {user.lastName} ({user.userName})</MenuItem>
                    ))}
                </Select>
            </FormControl>
        )
    }

    const renderEditUserForm = () => {
        if (!selectedUser?.id) {
            return (
                <StyledButton
                    type="button"
                    className={classes.secondaryButton}
                    onClick={handleAbort}
                    variant="outlined">
                    Abbrechen
                </StyledButton>
            );
        }

        return (
            <div>
                <form onSubmit={handleSubmit} autoComplete="off">
                    <FormControl fullWidth
                                 className={classes.formControl}
                                 style={{marginTop: "3rem"}}>
                        <FormHelperText>Benutzername *</FormHelperText>
                        <TextField
                            variant="standard"
                            required={false}
                            autoComplete="off"
                            margin="dense"
                            id="username"
                            name="username"
                            type="text"
                            inputProps={{
                                maxLength: 120
                            }}
                            value={userName}
                            onChange={e => setUserName(e.target.value)}
                        />
                    </FormControl>
                    <FormControl fullWidth
                                 className={classes.formControl}>
                        <FormHelperText>E-Mail *</FormHelperText>
                        <TextField
                            variant="standard"
                            required={false}
                            autoComplete="off"
                            margin="dense"
                            id="email"
                            name="email"
                            type="text"
                            inputProps={{
                                maxLength: 120
                            }}
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                    </FormControl>
                    <FormControl fullWidth
                                 className={classes.formControl}>
                        <FormHelperText>Neues Passwort</FormHelperText>
                        <TextField
                            variant="standard"
                            required={false}
                            autoFocus
                            autoComplete="off"
                            margin="dense"
                            id="password"
                            name="password"
                            type="password"
                            inputProps={{
                                maxLength: 120
                            }}
                            value={newPassword}
                            onChange={e => setNewPassword(e.target.value)}
                        />
                    </FormControl>
                    <FormControl fullWidth
                                 className={classes.formControl}>
                        <FormHelperText>Neues Passwort bestätigen</FormHelperText>
                        <TextField
                            variant="standard"
                            required={false}
                            autoFocus
                            autoComplete="off"
                            margin="dense"
                            id="passwordconfirm"
                            name="passwordconfirm"
                            type="password"
                            inputProps={{
                                maxLength: 120
                            }}
                            value={newPasswordConfirm}
                            onChange={e => setNewPasswordConfirm(e.target.value)}
                        />
                    </FormControl>
                    <FormControl required
                                 fullWidth
                                 className={classes.formControl}>
                        <FormHelperText>Vorname *</FormHelperText>
                        <TextField
                            variant="standard"
                            required={true}
                            autoFocus
                            autoComplete="off"
                            margin="dense"
                            id="firstname"
                            name="firstname"
                            type="text"
                            inputProps={{
                                maxLength: 120
                            }}
                            value={firstName}
                            onChange={e => setFirstName(e.target.value)}
                        />
                    </FormControl>
                    <FormControl required
                                 fullWidth
                                 className={classes.formControl}>
                        <FormHelperText>Nachname *</FormHelperText>
                        <TextField
                            variant="standard"
                            required={true}
                            autoComplete="off"
                            margin="dense"
                            id="firstname"
                            name="firstname"
                            type="text"
                            inputProps={{
                                maxLength: 120
                            }}
                            value={lastName}
                            onChange={e => setLastName(e.target.value)}
                        />
                    </FormControl>
                    <FormControl required fullWidth className={classes.formControl}>
                        <FormHelperText>Rolle *</FormHelperText>
                        <Select variant="standard" labelId="role" id="role" value={role}
                                onChange={e => handleRoleChange(e.target.value)}>
                            {getUserRoles().map((role, index) => (
                                <MenuItem value={role.key} key={index}>{role.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <StyledButton
                        type="button"
                        className={classes.secondaryButton}
                        onClick={handleAbort}
                        variant="outlined">
                        Abbrechen
                    </StyledButton>

                    <StyledButton
                        type="submit"
                        className={classes.primaryButton}>
                        Aktualisieren
                    </StyledButton>
                </form>
            </div>
        );
    }

    const renderAlert = () => {
        if (!errors.length) {
            return;
        }

        return (
            <Alert severity="error" style={{marginTop: "3rem"}}>
                {errors.length === 1 ?
                    errors[0]
                    :
                    <ul>
                        {errors.map((error, index) => <li key={index}>{error}</li>)}
                    </ul>
                }

            </Alert>
        )
    }

    useEffect((): void => {
        authenticationService.GetAllUsers(false).then(userResponse => {
            setUsers(userResponse.length ? userResponse : []);
            setStatus("");
        });
    }, []);

    if (status === "loading") {
        return (<div>loading...</div>);
    }

    if (status === "success") {
        return <Redirect push to={{
            pathname: "/user-management",
            state: {successMsg: "Benutzer wurde aktualisiert"}
        }}/>
    }

    if (status === "abort") {
        return <Redirect push to="/user-management"/>
    }

    return (
        <React.Fragment>
            <NavBar basicHeaderOnly={true}></NavBar>
            <CssBaseline/>
            <Container maxWidth="sm">
                <Typography variant="h5" component="h1" className={classes.title}>Bestehenden Benutzer
                    bearbeiten</Typography>
                {renderAlert()}
                {renderSelectUser()}
                {renderEditUserForm()}
            </Container>
        </React.Fragment>
    );
}

export default EditUser;