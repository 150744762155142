import React, { useState, useEffect } from 'react';
import { useAxios } from '../utils/httpClient';
import { FilterTasks } from '../components/filterTasks';
import { useStore } from '../state/store';

export interface IFilterTasksContainerProps {
  name: string;
  setData: any;
  setAreAllTasksVisible?: any;
}

export const FilterTasksContainer: React.FC<IFilterTasksContainerProps> = ({ name, setData, setAreAllTasksVisible }) => {
  const httpClient = useAxios();
  const [creators, setCreators] = useState<Array<any>>([]);
  const [assignees, setAssignees] = useState<Array<any>>([]);
  const [checked, setChecked] = useState(false);
  const { setOwnerId, setAssigneeId, assigneeId, ownerId } = useStore(state => ({
    setOwnerId: state.setOwnerId,
    setAssigneeId: state.setAssigneeId,
    assigneeId: state.assigneeId,
    ownerId: state.ownerId
  }));

  useEffect(() => {
    httpClient.get('users/profile').then(res => {
      const users = res.data.items;
      setAssignees(users);
      setCreators(users);
    });
  }, []);

  const handleChangeCreator = (event: React.ChangeEvent<{ value: string }>) => {
    setOwnerId(event.target.value as any);
  };

  const handleChangeAssignee = (event: React.ChangeEvent<{ value: string }>) => {
    setAssigneeId(event.target.value as any);
  };

  useEffect(() => performSearch(), [ownerId, assigneeId]);

  const performSearch = () => {
    if (setAreAllTasksVisible) {
      setAreAllTasksVisible(false);
    }
    httpClient
      .get(`tasks/${name}`, {
        params: {
          CreatorId: ownerId,
          AssigneeId: assigneeId
        }
      })
      .then(({ data }) => {
        const tasks = mapResponseToTasks(data, name);
        setData(tasks);
      })
      .catch(console.error);
  };

  const handleSwitch = (e: React.MouseEvent) => {
    setChecked(!checked);
    if (setAreAllTasksVisible) {
      setAreAllTasksVisible(false);
    }
    setOwnerId('');
    setAssigneeId('');
    if (checked) {
      httpClient
        .get(`tasks/${name}`)
        .then(({ data }) => {
          let tasks = mapResponseToTasks(data, name);
          setData(tasks);
        })
        .catch(console.error);
    }
  };

  return (
    <FilterTasks
      creatorId={ownerId}
      assigneeId={assigneeId}
      creators={creators}
      assignees={assignees}
      handleChangeCreator={handleChangeCreator}
      handleChangeAssignee={handleChangeAssignee}
      handleSwitch={handleSwitch}
      checked={checked}
    />
  );
};

function mapResponseToTasks(data: any, name: string) {
  let tasks = { ...data };
  if (name == 'mainboard') {
    const keys = Object.keys(data);
    tasks = { ...data };
    for (let key of keys) {
      tasks[key] = data[key].tasks.map((t: any) => ({ ...t, columnId: tasks[key].id }));
    }
  }
  return tasks;
}
