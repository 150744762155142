import { ColumnNames, IBoardSection, TaskStatus } from '../../types/board';
import { ColumnIds, IMainBoard } from '../../types/mainBoard';

export function createColumnsForBoard(mainBoard: IMainBoard): IBoardSection[] {
  return [
    {
      id: ColumnIds.tomorrow,
      title: ColumnNames.tomorrow,
      tasks: mainBoard?.tomorrow,
      type: TaskStatus.backlog,
      isDropDisabled: (source: string) => source !== ColumnIds.today && source !== ColumnIds.tomorrow
    },
    {
      id: ColumnIds.today,
      title: ColumnNames.today,
      tasks: mainBoard?.today,
      type: TaskStatus.backlog,
      isDropDisabled: (source: string) => source !== ColumnIds.tomorrow && source !== ColumnIds.today
    },
    {
      id: ColumnIds.inProgress,
      title: ColumnNames.inProgress,
      tasks: mainBoard?.inProgress,
      type: TaskStatus.inProgress,
      isDropDisabled: (source: string) =>
        source !== ColumnIds.tomorrow && source !== ColumnIds.today && source !== ColumnIds.review && source !== ColumnIds.inProgress
    },
    {
      id: ColumnIds.review,
      title: ColumnNames.review,
      tasks: mainBoard?.review,
      type: TaskStatus.review,
      isDropDisabled: (source: string) => source !== ColumnIds.inProgress && source !== ColumnIds.review
    },
    {
      id: ColumnIds.toBeFinished,
      title: ColumnNames.toBeFinished,
      tasks: mainBoard?.toBeFinished,
      type: TaskStatus.toBeFinished,
      isDropDisabled: (source: string) => source !== ColumnIds.review && source !== ColumnIds.toBeFinished && source !== ColumnIds.inProgress
    },
    {
      id: ColumnIds.done,
      title: ColumnNames.done,
      tasks: [],
      type: TaskStatus.done,
      isDropDisabled: (source: string) => source !== ColumnIds.toBeFinished
    }
  ];
}
