import React from 'react';
import {FormControl, FormHelperText, MenuItem, TextField} from "@mui/material";
import {ValidTaskEfforts} from "../types/tasks";

interface ITaskFormEffortProps {
  classes: Record<"row" | "inputElement" | "saveButton", string>,
  effortInForm: string,
  handleFormEffortChange: (e: any) => void,
  effortInModel: number,
  handleModelEffortChange: (e: any) => void
}

export function TaskFormEffort(props: ITaskFormEffortProps) {
  return <>
    <FormControl className={props.classes.inputElement}>
      <FormHelperText>Arbeitszeit</FormHelperText>
      <TextField
        id="effort"
        margin="dense"
        select
        value={props.effortInForm}
        required={true}
        onChange={props.handleFormEffortChange}
        variant="standard"
      >
        <MenuItem value={ValidTaskEfforts.fifteenMinutes}>15 Minuten</MenuItem>
        <MenuItem value={ValidTaskEfforts.halfHour}>30 Minuten</MenuItem>
        <MenuItem value={ValidTaskEfforts.oneHour}>1 Stunde</MenuItem>
        <MenuItem value={ValidTaskEfforts.twoHours}>2 Stunden</MenuItem>
        <MenuItem value={ValidTaskEfforts.threeHours}>3 Stunden</MenuItem>
        <MenuItem value={ValidTaskEfforts.fourHours}>4 Stunden</MenuItem>
        <MenuItem value={ValidTaskEfforts.fiveHours}>5 Stunden</MenuItem>
        <MenuItem value={ValidTaskEfforts.other}>Andere (in Stunden)</MenuItem>
      </TextField>
    </FormControl>
    {props.effortInForm === ValidTaskEfforts.other && (
      <FormControl className={props.classes.inputElement}>
        <FormHelperText>Arbeitsstunden</FormHelperText>
        <TextField
          variant="standard"
          margin="dense"
          id="effort-other"
          type="number"
          InputProps={{inputProps: {min: 0}}}
          value={props.effortInModel}
          onChange={props.handleModelEffortChange}
        />
      </FormControl>
    )}
  </>;
}