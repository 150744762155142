import { IBoardTask } from './tasks';

export enum WeekSectionIds {
  currentWeek = 'currentWeek',
  nextWeek = 'nextWeek',
  restWeeksTasks = 'restWeeksTasks',
  inProgress = 'inProgress'
}

export interface IBacklogEffort {
  totalEffort: number;
}

export interface IBacklog {
  currentWeek: IBoardTask[];
  nextWeek: IBoardTask[];
  restWeeksTasks: IBoardTask[];
  totalEffort: IBacklogEffort;
}
