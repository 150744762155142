import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  backdrop: {
    zIndex: 2,
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    '& div': {
      marginBottom: '2em'
    }
  }
});

export const LoadingSpinner = () => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress size="8em" className={classes.buttonProgress} />
    </Backdrop>
  );
};
