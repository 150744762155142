import React, { createContext, useContext } from 'react';
import { useRealtimeConnection } from '../hooks/useRealtimeConnection';
import { IRealTimeState } from '../types/realtime';

const RealTimeStateContext = createContext({
  onUpdate: Function,
  clear: Function
} as IRealTimeState);

export const RealTimeProvider: React.FC<{}> = ({ children }) => {
  const connection = useRealtimeConnection();

  const handleNotification = (updateHandler: Function) => connection?.on('refreshBoard', () => updateHandler() as any);

  const clearNotificationHandler = () => connection?.off('refreshBoard');

  return (
    <RealTimeStateContext.Provider value={{ onUpdate: handleNotification, clear: clearNotificationHandler }}>
      {children}
    </RealTimeStateContext.Provider>
  );
};

export const useRealtimeClient = () => useContext(RealTimeStateContext);
