import React, { useState } from 'react';
import { Icon, IconButton, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { CreateTaskContainer } from '../containers/createTaskContainer';

interface ITitle {
  title: string;
  columnId: string;
}

const useStylesTitle = makeStyles((theme: Theme) =>
  createStyles({
    titleContainer: {
      height: '30px',
      display: 'flex',
      backgroundColor: theme.palette.primary.main,
      alignItems: 'center',
      justifyContent: 'flex-start',
      borderTopLeftRadius: '6px',
      borderTopRightRadius: '6px',
      position: 'sticky',
      top: 0
    },
    circle: {
      minwidth: '20px',
      width: '20px',
      height: '20px',
      marginLeft: '7px'
    },
    title: {
      padding: '5px',
      fontWeight: 500,
      color: 'white',
      textAlign: 'center',
      fontSize: '1.3em',
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    plus: {
      color: 'white',
      borderRadius: '50%',
      marginLeft: 'auto'
    }
  })
);

export function ColumnTitle(props: ITitle) {
  const classes = useStylesTitle(props);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);

  const handleOpen = () => setIsTaskModalOpen(true);
  const handleClose = () => setIsTaskModalOpen(false);

  const isAddVisible: boolean = props.title === 'Morgen' || props.title === 'Heute';

  return <>
    <div className={classes.titleContainer}>
      <div className={classes.circle} />
      <Typography className={classes.title} variant="h5">
        {props.title}
      </Typography>
      {isAddVisible && (
        <IconButton onClick={handleOpen} size="large">
          <Icon className={classes.plus}>add_circle</Icon>
        </IconButton>
      )}
    </div>
    {isTaskModalOpen && <CreateTaskContainer isOpen={isTaskModalOpen} columnId={props.columnId} closeSelf={handleClose} />}
  </>;
}
