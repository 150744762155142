import axios from 'axios';

export const serverUrl = process.env.REACT_APP_API_URL ?? 'http://localhost:5000';
const apiVersion = 'V1';
const apiUrl = `${serverUrl}/api/${apiVersion}/`;

export const useAxios = (withCredentials: boolean = false) => {
    const axiosInstance = axios.create({
        baseURL: apiUrl,
        withCredentials: withCredentials,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    return axiosInstance;
};
