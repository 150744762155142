import * as React from 'react';
import {useEffect, useState} from 'react';
import type {RouteProps} from 'react-router-dom';
import {Redirect, Route, RouteComponentProps} from 'react-router-dom';
import {authenticationService} from '../services/AuthenticationService';

interface PrivateRouteParams extends RouteProps {
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

export const PrivateRoute = ({component: Component, ...rest}: PrivateRouteParams) => {
    const isLoggedIn = authenticationService.useIsLoggedIn();
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const checkAuthentication = async () => {
            setIsAuthenticated((await isLoggedIn()).isLoggedIn);
            setIsLoading(false);
        };

        checkAuthentication();
    }, []);

    if (isLoading) {
        return <div>Loading...</div>; // Oder eine andere Ladeanzeige
    }

    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: {from: props.location}
                        }}
                    />
                )
            }
        />
    );
}

export function AdminRoute({component: Component, ...rest}: PrivateRouteParams) {
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const checkAuthentication = async () => {
            setIsLoading(true);
            const isAdmin = await authenticationService.IsAdmin();
            setIsAdmin(isAdmin);
            setIsLoading(false);
        };

        checkAuthentication();
    }, []);

    if (isLoading) {
        return <div>Loading...</div>; // Oder eine andere Ladeanzeige
    }

    return (
        <Route
            {...rest}
            render={props =>
                isAdmin ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: {from: props.location}
                        }}
                    />
                )
            }
        />
    );
}
