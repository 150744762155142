import React, {MouseEvent, useEffect, useState} from 'react';
import {Container, Paper, styled, Typography} from '@mui/material';
import {useHistory} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import {UploadAvatar} from './uploadAvatar';
import {PickColor} from './pickColor';
import {NavBar} from './navbar';
import {fileSizeValidator} from '../utils/sizeValidator';
import {useAxios} from '../utils/httpClient';
import {encodeImageToBase64, validateFileExtension} from '../utils/imageEncoder';
import {StyledButton} from './styledButton';
import {ChangePassword} from './changePassword';

const OuterContainer = styled(Container)(({theme}) => ({
    marginTop: theme.spacing(2),
    flexDirection: 'column',
    overflow: 'hidden',
    padding: theme.spacing(2),
    textAlign: 'center'
}));

const InnerContainer = styled(Paper)(({theme}) => ({
    marginTop: theme.spacing(2),
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: '#F6F6F6',
    padding: theme.spacing(2),
    textAlign: 'center'
}));

interface IProfileSettingsProps {
    handleSettingsUpload: Function;
    selectedColor: string;
    handleColorSelection: Function;
    encodedImage: string;
    handleEncodedImageSet: Function;
}

export const ProfileSettings: React.FC<IProfileSettingsProps> = ({
                                                                     handleSettingsUpload,
                                                                     selectedColor,
                                                                     handleColorSelection,
                                                                     encodedImage,
                                                                     handleEncodedImageSet
                                                                 }) => {
    const [colors, setColors] = useState<string[]>([]);
    const httpClient = useAxios();

    useEffect(() => {
        httpClient.get('users/colors/available').then(r => {
            setColors(r.data.items.map((i: any) => i.color));
        });
    }, []);

    const selectColor = (e: MouseEvent, color: string) => {
        handleColorSelection(color);
    };

    const {enqueueSnackbar} = useSnackbar();
    const history = useHistory();

    const createErrorMessage = (message: string) => {
        enqueueSnackbar(message, {
            variant: 'error',
            autoHideDuration: 3000
        });
    };

    const handleImageSelect = (files: FileList | null): void => {
        if (files == null) {
            return;
        }

        const file = files[0];
        if (!validateFileExtension(file)) {
            createErrorMessage('An image must be provided for the avatar');
            return;
        }

        if (!fileSizeValidator(file)) {
            createErrorMessage('An image less than 2MB must be provided for the avatar');
            return;
        }

        encodeImageToBase64(file, handleEncodedImageSet);
    };

    const uploadSettings = (e: MouseEvent) => {
        e.preventDefault();
        handleSettingsUpload();
    };

    return (
        <div>
            <NavBar/>
            <OuterContainer>
                <Typography variant="h4" component="h1">
                    Profileinstellungen
                </Typography>
                <InnerContainer>
                    <ChangePassword/>
                </InnerContainer>
                <InnerContainer>
                    <UploadAvatar encodedImage={encodedImage} handleImageSelect={handleImageSelect}/>
                    <PickColor selectColor={selectColor} colors={colors} selectedColor={selectedColor}/>
                </InnerContainer>
                <StyledButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{color: 'white', margin: '20px'}}
                    disabled={encodedImage === '' || selectedColor === ''}
                    onClick={uploadSettings}
                    disableElevation
                >
                    Beenden
                </StyledButton>
            </OuterContainer>
        </div>
    );
};
