import React, {useState} from 'react';
import {useHistory} from 'react-router';
import {ProfileSettings} from '../components/profileSettings';
import {useAxios} from '../utils/httpClient';

export const ProfileInitContainer = () => {
    const [selectedColor, setSelectedColor] = useState('');
    const [encodedImage, setEncodedImage] = useState('');
    const httpClient = useAxios();
    const history = useHistory();

    const handleSettingsUpload = () => {
        httpClient
            .post('users/profile', {
                image: encodedImage.split(',')[1],
                color: selectedColor
            })
            .then(() => history.push('/board'));
    };

    return (
        <>
            <ProfileSettings
                handleSettingsUpload={handleSettingsUpload}
                selectedColor={selectedColor}
                handleColorSelection={setSelectedColor}
                encodedImage={encodedImage}
                handleEncodedImageSet={setEncodedImage}
            />
        </>
    );
};
