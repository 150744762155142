import React from 'react';
import { FormControl, FormHelperText, Select, MenuItem, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from '@mui/icons-material/Search';
import { StyledButton } from './styledButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      maxWidth: '100%',
      flexWrap: 'wrap',
      boxSizing: 'border-box',
      padding: '0.4em 0',
      justifyContent: 'center'
    },
    fieldsContainer: {
      display: 'flex',
      margin: '0 10px 0 10px',
      alignItems: 'center'
    },
    title: {
      color: theme.palette.primary.main,
      fontWeight: 600,
      fontSize: '1.3em'
    },
    field: {
      width: '150px',
      margin: '0 5px 0 5px',
      '& p, div, svg': {
        color: 'white'
      },
      '& >div::before': {
        borderBottomColor: 'white'
      },
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23) !important',
        borderWidth: '1px !important'
      }
    }
  })
);

interface IFilterTasksProps {
  creatorId: any;
  assigneeId: any;
  creators: any[];
  assignees: any[];
  handleChangeCreator: (event: React.ChangeEvent<{ value: string }>) => void;
  handleChangeAssignee: (event: React.ChangeEvent<{ value: string }>) => void;
  handleSwitch: (e: React.MouseEvent) => void;
  checked: any;
}

export const FilterTasks: React.FC<IFilterTasksProps> = ({
  creatorId,
  assigneeId,
  creators,
  assignees,
  handleChangeCreator,
  handleChangeAssignee,
  handleSwitch,
  checked
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div id="title" className={classes.title}>
        <StyledButton style={{ fontSize: '1em', color: 'white' }} onClick={handleSwitch} name="checked">
          <SearchIcon />
        </StyledButton>
      </div>
      {checked ? (
        <div className={classes.fieldsContainer}>
          <FormControl className={classes.field} fullWidth>
            <FormHelperText>Inhaber</FormHelperText>
            <Select
              labelId="creator"
              id="creator"
              value={creatorId}
              onChange={e => handleChangeCreator(e as React.ChangeEvent<{ value: string }>)}
            >
              {creators.map(creator => (
                <MenuItem value={creator.id} key={creator.id}>
                  {creator.firstName} {creator.lastName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.field} fullWidth>
            <FormHelperText>Bearbeiter</FormHelperText>
            <Select
              labelId="assignee"
              id="assignee"
              value={assigneeId}
              onChange={e => handleChangeAssignee(e as React.ChangeEvent<{ value: string }>)}
            >
              {assignees.map(assignee => (
                <MenuItem value={assignee.id} key={assignee.id}>
                  {assignee.firstName} {assignee.lastName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      ) : null}
    </div>
  );
};
