import React from 'react';
import { styled } from '@mui/material/styles';
import { Button, ButtonProps } from '@mui/material';

const StyledButtonComponent = styled(Button)({
  textTransform: 'none'
});

export function StyledButton(props: ButtonProps) {
  const { children, ...other } = props;
  return <StyledButtonComponent {...other}>{children}</StyledButtonComponent>;
}
