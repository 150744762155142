import React, { FocusEvent, KeyboardEvent } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Chip, TextField } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';

interface ITaskFormLinksParams {
  links: string[];
  onClick: () => void;
  showLinkInput: boolean;
  onBlur: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onKeyPress: (e: KeyboardEvent<HTMLDivElement>) => void;
  handleLinkRemoval: (link: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: '5px'
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center'
    },
    list: {
      listStyle: 'none',
      paddingLeft: 0
    },
    listItem: {
      marginTop: 0
    },
    chip: {
      margin: theme.spacing(0.5)
    }
  })
);

export function TaskFormLinks(props: ITaskFormLinksParams) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <b>Links</b>
      </div>
      <div className={classes.row}>
        <ul className={`${classes.list} ${classes.row}`}>
          {props.links.map((link, idx) => (
            <li key={link} className={classes.listItem}>
              <Chip
                label={
                  <a href={link} target="_blank">
                    {link}
                  </a>
                }
                onDelete={_ => props.handleLinkRemoval(link)}
                className={classes.chip}
              />
            </li>
          ))}
        </ul>
        <AddIcon onClick={props.onClick} />
        {props.showLinkInput && <TextField variant="filled" size="small" onBlur={props.onBlur} onKeyPress={props.onKeyPress} autoFocus />}
      </div>
    </div>
  );
}
