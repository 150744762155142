declare global {
  interface Date {
    ConvertToYearMonthDayString(): string;
  }
}

Date.prototype.ConvertToYearMonthDayString = function (): string {
  return this.getFullYear() + '-' + ('0' + (this.getMonth() + 1)).slice(-2) + '-' + ('0' + this.getDate()).slice(-2);
};

export {};
