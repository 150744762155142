import {
    Alert,
    Container,
    FormControl,
    FormHelperText,
    Paper,
    styled,
    TextField,
    Theme,
    Typography
} from '@mui/material';
import React, {FormEvent, useState} from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import {StyledButton} from './styledButton';
import {authenticationService} from '../services/AuthenticationService';

const InnerContainer = styled(Paper)(({theme}) => ({
    marginTop: theme.spacing(2),
    flexDirection: 'column',
    overflow: 'hidden',
    padding: theme.spacing(2),
    textAlign: 'center'
}));

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            marginTop: "1rem"
        },
        button: {
            marginTop: "1rem",
            color: 'white',
            background: theme.palette.primary.main,
            '&:hover': {
                background: theme.palette.secondary.main,
                color: 'white'
            }
        },
        errorList: {
            textAlign: "left"
        }
    })
);

export const ChangePassword = () => {
    const classes = useStyles();
    const [oldPassword, setOldPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>("");
    const [status, setStatus] = useState<string>("");
    const [errors, setErrors] = useState<string[]>([]);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setErrors([]);
        setStatus("loading");

        authenticationService.ChangePassword(oldPassword, newPassword, newPasswordConfirm).then(response => {
            if (response.succeeded) {
                setStatus("success");
                setOldPassword("");
                setNewPassword("");
                setNewPasswordConfirm("");
            } else {
                setStatus("");
                setErrors(response.errors);
            }
        })
    }

    const renderForm = () => {
        return (
            <form onSubmit={handleSubmit} autoComplete="off">
                <FormControl required
                             fullWidth
                             className={classes.formControl}>
                    <FormHelperText>Aktuelles Passwort *</FormHelperText>
                    <TextField
                        variant="standard"
                        required={false}
                        autoComplete="off"
                        margin="dense"
                        id="oldPassword"
                        name="oldPassword"
                        type="password"
                        inputProps={{
                            maxLength: 120
                        }}
                        value={oldPassword}
                        onChange={e => setOldPassword(e.target.value)}
                    />
                </FormControl>
                <FormControl required
                             fullWidth
                             className={classes.formControl}>
                    <FormHelperText>Neues Passwort *</FormHelperText>
                    <TextField
                        variant="standard"
                        required={false}
                        autoComplete="off"
                        margin="dense"
                        id="newPassword"
                        name="newPassword"
                        type="password"
                        inputProps={{
                            maxLength: 120
                        }}
                        value={newPassword}
                        onChange={e => setNewPassword(e.target.value)}
                    />
                </FormControl>
                <FormControl required
                             fullWidth
                             className={classes.formControl}>
                    <FormHelperText>Neues Passwort bestätigen *</FormHelperText>
                    <TextField
                        variant="standard"
                        required={false}
                        autoComplete="off"
                        margin="dense"
                        id="newPasswordConfirm"
                        name="newPasswordConfirm"
                        type="password"
                        inputProps={{
                            maxLength: 120
                        }}
                        value={newPasswordConfirm}
                        onChange={e => setNewPasswordConfirm(e.target.value)}
                    />
                </FormControl>

                <StyledButton
                    type="submit"
                    className={classes.button}>
                    Passwort ändern
                </StyledButton>
            </form>
        );
    }

    const renderSuccessAlert = () => {
        if (status !== "success") {
            return;
        }

        return (
            <Alert severity="success" style={{marginTop: "3rem"}}>
                Das Passwort wurde geändert
            </Alert>
        );
    }

    const renderAlert = () => {
        if (!errors.length) {
            return;
        }

        return (
            <Alert severity="error">
                {errors.length === 1 ?
                    errors[0]
                    :
                    <ul className={classes.errorList}>
                        {errors.map((error, index) => <li key={index}>{error}</li>)}
                    </ul>
                }

            </Alert>
        )
    }

    return (
        <Container maxWidth="sm">
            <InnerContainer>
                <Typography variant="h6" component="h1" style={{marginBottom: '27px'}}>
                    Neues Passwort setzen
                </Typography>
                {status === "loading" ? <div>processing...</div> : ""}
                {renderSuccessAlert()}
                {renderAlert()}
                {renderForm()}
            </InnerContainer>
        </Container>
    );
}

export default ChangePassword;