import React from 'react';
import { styled } from '@mui/material/styles';
import { Droppable } from 'react-beautiful-dnd';
import Task from './task';
import { IBoardSection } from '../types/board';
import { Box, BoxProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ColumnTitle } from './columnTitle';

interface IStyleProps {
  isActive: boolean;
}

export interface ColumnProps {
  key: string;
  column: IBoardSection;
  isDropDisabled: boolean;
}

const Container = styled(Box)({
  margin: '0.5em',
  width: '20%',
  flexDirection: 'column',
  overflow: 'auto',
  backgroundColor: '#EdEdF3',
  '&::-webkit-scrollbar': {
    display: 'none'
  },
  scrollbarWidth: 'none',
  msOverflowStyle: 'none'
});

const useStylesListContainer = makeStyles({
  listContainer: {
    padding: '8px',
    backgroundColor: (props: IStyleProps) => (props.isActive ? '#cecece' : '#EBECF0'),
    flexGrow: 1,
    minHeight: '100px'
  }
});

function TaskList(props: IStyleProps & Omit<BoxProps, keyof IStyleProps>) {
  const { isActive, children } = props;
  const classes = useStylesListContainer(props);
  return <div className={classes.listContainer}>{children}</div>;
}

export const Column: React.FC<ColumnProps> = ({ column, isDropDisabled }) => {
  return (
    <Container>
      <ColumnTitle title={column.title} columnId={column.id} />
      <Droppable droppableId={column.id} isDropDisabled={isDropDisabled}>
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            <TaskList isActive={snapshot.isDraggingOver && snapshot.draggingOverWith !== null}>
              {column.tasks?.map((task, idx) => (
                <Task columnTitle={column.title} key={task.id} task={task} index={idx} />
              ))}
              {provided.placeholder}
            </TaskList>
          </div>
        )}
      </Droppable>
    </Container>
  );
};
