import React from 'react';
import { Box, styled } from '@mui/material';
import { BoardLayout } from './boardLayout';
import { IBoardSection } from '../types/board';

const Container = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  overflowY: 'auto'
});

interface IMainBoardProps {
  isDoneDisabled: boolean;
  lastColumn: IBoardSection;
  columns: JSX.Element[];
  setData: any;
}

export const MainBoard: React.FC<IMainBoardProps> = ({ isDoneDisabled, lastColumn, columns, setData }) => {
  return (
    <BoardLayout isDropDisabled={isDoneDisabled} section={lastColumn} dropName="Erledigt" name="mainboard" setData={setData}>
      <Container>{columns}</Container>
    </BoardLayout>
  );
};
