import create from 'zustand';
import {IMainBoard} from '../types/mainBoard';
import {IDone} from '../types/done';
import {IBacklog} from '../types/backlog';
import {IUser} from '../types/user';

type StoreState = {
    mainBoard: IMainBoard;
    backlog: IBacklog;
    done: IDone;
    user: IUser;
    ownerId: string;
    assigneeId: string;
    isDropAreaVisible: boolean;
    setMainBoard: (mainBoard: IMainBoard) => void;
    setBacklog: (backlog: IBacklog) => void;
    setDone: (done: IDone) => void;
    setUser: (user: IUser) => void;
    resetUser: (user: IUser) => void;
    setOwnerId: (ownerId: string) => void;
    setAssigneeId: (assigneeId: string) => void;
    setIsDropAreaVisible: (isDoneVisible: boolean) => void;
};

export const useStore = create<StoreState>(set => ({
    mainBoard: <IMainBoard>{},
    backlog: <IBacklog>{},
    done: <IDone>{},
    user: <IUser>{},
    ownerId: '',
    assigneeId: '',
    isDropAreaVisible: false,
    setMainBoard: mainBoard => set(state => ({mainBoard})),
    setBacklog: backlog => set(state => ({backlog})),
    setDone: done => set(state => ({done})),
    setUser: user => set(state => ({user})),
    resetUser: user => set(state => ({})),
    setOwnerId: ownerId => set(state => ({ownerId})),
    setAssigneeId: assigneeId => set(state => ({assigneeId})),
    setIsDropAreaVisible: isDoneVisible => set(state => ({isDropAreaVisible: isDoneVisible}))
}));
