import React, {useEffect} from 'react';
import {useAxios} from '../utils/httpClient';
import {useHistory} from 'react-router-dom';

export const ProfileCheck = () => {
    const httpClient = useAxios(true);

    let history = useHistory();

    useEffect(() => {
        httpClient
            .put<boolean>('users/profile')
            .then(({data}) => {
                const redirectUrl = data ? '/profile-settings' : '/board';
                history.push(redirectUrl);
            })
            .catch(console.error);
    }, []);

    return <div>Checking user profile.</div>;
};
