import React, {useEffect, useState} from 'react';
import {DragDropContext, DraggableLocation, DragStart, DropResult} from 'react-beautiful-dnd';
import {Column} from '../components/column';
import {useAxios} from '../utils/httpClient';
import {useStore} from '../state/store';
import {IBoardSection, TaskStatus} from '../types/board';
import {createColumnsForBoard} from '../utils/mappers/mainBoardToColumns';
import {ColumnIds, IMainBoard, IMainBoardDto} from '../types/mainBoard';
import {AxiosInstance} from 'axios';
import {MainBoard} from '../components/mainBoard';
import {useRealtimeClient} from '../state/realtime.context';
import {IBoardTask} from '../types/tasks';

function moveCardToNewColumn(
    originColumn: IBoardSection | undefined,
    source: DraggableLocation,
    destinationColumn: IBoardSection | undefined,
    destinationIndex: number
) {
    const task = originColumn?.tasks[source.index];
    originColumn?.tasks.splice(source.index, 1);

    if (task && destinationColumn) {
        destinationColumn.tasks.splice(destinationIndex, 0, task);
    }
}

function isDestinationSameAsSource(destination: DraggableLocation, source: DraggableLocation): boolean {
    return destination.droppableId === source.droppableId && destination.index === source.index;
}

function fetchTasks(httpClient: AxiosInstance, setMainBoard: (mainBoard: IMainBoard) => void, ownerId?: string, assigneeId?: string) {
    httpClient
        .get<IMainBoardDto>('tasks/mainboard', {
            params: {
                CreatorId: ownerId,
                AssigneeId: assigneeId
            }
        })
        .then(({data}) => {
            const keys = Object.keys(data);
            const tasks: any = {...data};
            for (let key of keys) {
                tasks[key] = data[key].tasks.map(t => ({...t, columnId: tasks[key].id}));
            }
            setMainBoard(tasks);
        })
        .catch(console.error);
}

function updateTaskStatus(
    httpClient: AxiosInstance,
    taskId: string,
    newStatus: TaskStatus,
    previousTaskId?: number | null,
    nextTaskId?: number | null
) {
    return httpClient.patch(`tasks/${taskId}`, {
        inputState: newStatus,
        previousTaskId,
        nextTaskId
    });
}

function moveIsFromTomorrowToToday(originColumn: IBoardSection | undefined, destinationColumn: IBoardSection): boolean {
    return originColumn?.id == ColumnIds.tomorrow && destinationColumn.id == ColumnIds.today;
}

function moveIsFromTodayToTomorrow(destinationColumn: IBoardSection, originColumn: IBoardSection | undefined) {
    return originColumn?.id == ColumnIds.today && destinationColumn.id == ColumnIds.tomorrow;
}

function handleInColumnOrdering(
    destinationColumn: IBoardSection | undefined,
    destination: DraggableLocation,
    source: DraggableLocation,
    httpClient: AxiosInstance,
    task: IBoardTask | undefined
) {
    const amountOfTasks = (destinationColumn?.tasks.length ?? 0) - 1;

    const previousTaskId = getPreviousTask();

    const nextTaskId = getNextTask();

    httpClient.patch('/column-orderings', {
        taskId: task?.id,
        previousTaskId,
        nextTaskId,
        columnId: task?.columnId
    });

    function getNextTask() {
        let nextTaskIndex = destination.index > source.index ? destination.index + 1 : destination.index;
        if (destination.index == amountOfTasks) nextTaskIndex = -1;
        else if (destination.index == 0) nextTaskIndex = destination.index;

        const nextTaskId = nextTaskIndex >= 0 ? destinationColumn?.tasks[nextTaskIndex].id : null;
        return nextTaskId;
    }

    function getPreviousTask() {
        let previousTaskIndex = destination.index > source.index ? destination.index : destination.index - 1;

        if (destination.index == 0) previousTaskIndex = -1;
        else if (destination.index == amountOfTasks) previousTaskIndex = destination.index;
        const previousTaskId = previousTaskIndex >= 0 ? destinationColumn?.tasks[previousTaskIndex].id : null;
        return previousTaskId;
    }
}

export const BoardContainer = () => {
    const httpClient = useAxios();
    const setMainBoard = useStore(state => state.setMainBoard);
    const setIsDropAreaVisible = useStore(state => state.setIsDropAreaVisible);
    const [dragOrigin, setDragOrigin] = useState('');
    const realtimeClient = useRealtimeClient();
    const {ownerId, assigneeId} = useStore(state => ({ownerId: state.ownerId, assigneeId: state.assigneeId}));
    const user = useStore(state => state.user);

    useEffect(() => {
        const updateHandler = () => fetchTasks(httpClient, setMainBoard, ownerId, assigneeId);

        realtimeClient.onUpdate(updateHandler);

        return () => realtimeClient.clear();
    }, [ownerId, assigneeId]);

    useEffect(() => {
        fetchTasks(httpClient, setMainBoard);
    }, []);

    const mainBoard = useStore(state => state.mainBoard);

    const columns: IBoardSection[] = createColumnsForBoard(mainBoard);

    const onDragEnd = (result: DropResult) => {
        const {destination, source, draggableId} = result;

        if (source.droppableId === ColumnIds.toBeFinished) {
            setIsDropAreaVisible(false);
        }

        if (!destination || isDestinationSameAsSource(destination, source)) return;

        const originColumn = columns.find(c => c.id == source.droppableId);
        const destinationColumn = columns.find(c => c.id == destination.droppableId);

        const task = originColumn?.tasks[source.index];

        // Reordering in list
        if (!destinationColumn || originColumn?.id === destinationColumn?.id) {
            handleInColumnOrdering(destinationColumn, destination, source, httpClient, task);
            moveCardToNewColumn(originColumn, source, destinationColumn, destination.index);
            return;
        }

        const previousTaskId = destination.index > 0 ? destinationColumn?.tasks[destination.index - 1].id : null;
        const nextTaskId = destination.index < (destinationColumn?.tasks.length ?? 0) ? destinationColumn?.tasks[destination.index].id : null;

        if (moveIsFromTodayToTomorrow(destinationColumn, originColumn) || moveIsFromTomorrowToToday(originColumn, destinationColumn)) {
            if (user.id !== task?.creator.id) return;
        }

        updateTaskStatus(httpClient, draggableId, destinationColumn.type, previousTaskId, nextTaskId);

        moveCardToNewColumn(originColumn, source, destinationColumn, destination.index);
    };

    const onDragStart = (start: DragStart) => {
        if (start.source.droppableId === ColumnIds.toBeFinished) {
            setIsDropAreaVisible(true);
        }
        const origin = start.source.droppableId;
        setDragOrigin(origin);
    };

    const columnElements = columns.slice(0, columns.length - 1).map(column => {
        return <Column key={column.id} column={column} isDropDisabled={column.isDropDisabled(dragOrigin)}/>;
    });

    const lastColumn = columns[columns.length - 1];
    const isDoneDisabled = lastColumn.isDropDisabled(dragOrigin);

    return (
        <div>
            <DragDropContext onDragEnd={r => onDragEnd(r)} onDragStart={r => onDragStart(r)}>
                <MainBoard columns={columnElements} isDoneDisabled={isDoneDisabled} lastColumn={lastColumn}
                           setData={setMainBoard}/>
            </DragDropContext>
        </div>
    );
};
