import React from 'react';
import { Box, styled } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { BoardLayout } from './boardLayout';
import { IBoardSection } from '../types/board';
import { IBacklogEffort } from '../types/backlog';
import { FilterTasksContainer } from '../containers/filterTasksContainer';

const Container = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  overflowY: 'auto'
});

const useStyles = makeStyles({
  effort: {
    margin: '8px 16px 8px 16px',
    fontWeight: 'bold',
    fontSize: '1.3em'
  }
});

interface IBacklogProps {
  isInProgressDisabled: boolean;
  lastSection: IBoardSection;
  totalEffort: IBacklogEffort;
  sectionElements: JSX.Element[];
  setData: any;
}

export const Backlog: React.FC<IBacklogProps> = ({ isInProgressDisabled, lastSection, totalEffort, sectionElements, setData }) => {
  const classes = useStyles();

  return (
    <BoardLayout isDropDisabled={isInProgressDisabled} section={lastSection} dropName="In Arbeit" name="backlog" setData={setData}>
      <Typography className={classes.effort}>Gesamtaufwand: {totalEffort}</Typography>
      <Container>{sectionElements}</Container>
    </BoardLayout>
  );
};
