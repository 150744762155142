import React from 'react';
import { IBoardTask } from '../types/tasks';
import { Draggable } from 'react-beautiful-dnd';
import Card from './card';

interface TaskProps {
  task: IBoardTask;
  index: number;
  columnTitle: string;
}

const Task: React.FC<TaskProps> = ({ task, index, columnTitle }) => {
  return (
    <Draggable draggableId={task.id.toString()} index={index} key={task.id.toString()}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps}>
          <Card columnTitle={columnTitle} task={task} isDragging={snapshot.isDragging} />
        </div>
      )}
    </Draggable>
  );
};

export default Task;
