import React from 'react';
import '@atlaskit/css-reset';
import {SnackbarProvider} from 'notistack';
import {AppRouter} from './routes';
import theme from './utils/theme';
import {RealTimeProvider} from './state/realtime.context';
import {ThemeProvider} from '@mui/material';
import {StyledEngineProvider} from '@mui/material/styles';

const rootUrl = window.location.origin;

function App(): JSX.Element {
    return (
        <ThemeProvider theme={theme}>
            <StyledEngineProvider injectFirst>
                <SnackbarProvider
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                >
                    <RealTimeProvider>
                        <AppRouter/>
                    </RealTimeProvider>
                </SnackbarProvider>
            </StyledEngineProvider>
        </ThemeProvider>
    );
}

export default App;
