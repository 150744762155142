import React, {FormEvent, useEffect, useState} from 'react';
import {authenticationService} from '../services/AuthenticationService';
import {Redirect} from "react-router-dom";
import {Alert, Container, CssBaseline, FormControl, FormHelperText, TextField, Theme} from "@mui/material";
import {StyledButton} from './styledButton';
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import {NavBar} from './navbar';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        saveButton: {
            marginTop: "1rem",
            color: 'white',
            background: theme.palette.primary.main,
            '&:hover': {
                background: theme.palette.secondary.main,
                color: 'white'
            }
        }
    })
);

export const Login = () => {
    const classes = useStyles();
    const login = authenticationService.useLogin();
    const isLoggedIn = authenticationService.useIsLoggedIn();
    const [userName, setUserName] = useState<string | null>(null);
    const [password, setPassword] = useState<string | null>(null);
    const [status, setStatus] = useState<string>("loading");
    const [errors, setErrors] = useState<string[]>([]);

    useEffect(() => {
        const checkAuthentication = async () => {
            const userIsLoggedIn = (await isLoggedIn()).isLoggedIn;
            setStatus(userIsLoggedIn ? "loggedIn" : "");
        };

        checkAuthentication();
    }, []);

    const handleLoginSubmit = (evt: FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        setStatus('submitting');
        try {
            login(userName ?? "", password ?? "").then(response => {
                if (response.succeeded) {
                    setStatus('success');
                } else {
                    setStatus('');
                    setErrors(response.errors);
                }
            });
        } catch (e: any) {
            setStatus('typing');
            console.error(e);
        }
    }

    const renderAlert = () => {
        if (!errors.length) {
            return;
        }

        return (
            <Alert severity="error" style={{marginTop: "3rem"}}>
                {errors.length === 1 ?
                    errors[0]
                    :
                    <ul>
                        {errors.map((error, index) => <li key={index}>{error}</li>)}
                    </ul>
                }

            </Alert>
        )
    }

    if (status === 'loading') {
        return <div>Loading...</div>
    }

    if (['success', 'loggedIn'].includes(status)) {
        return <Redirect to="/"/>
    }

    return (
        <React.Fragment>
            <NavBar basicHeaderOnly={true}></NavBar>
            <CssBaseline/>
            <Container maxWidth="sm">
                {renderAlert()}
                {status === "typing" && <div className="error">Benutzer nicht gefunden</div>}

                <form onSubmit={e => handleLoginSubmit(e)}>
                    <FormControl required fullWidth style={{marginTop: "3rem"}}>
                        <FormHelperText>Benutzername *</FormHelperText>
                        <TextField
                            variant="standard"
                            required={true}
                            autoFocus
                            autoComplete="on"
                            margin="dense"
                            id="username"
                            name="username"
                            type="text"
                            inputProps={{
                                maxLength: 120
                            }}
                            value={userName ?? ""}
                            onChange={e => setUserName(e.target.value)}
                        />
                    </FormControl>
                    <FormControl required fullWidth style={{marginTop: "1rem"}}>
                        <FormHelperText>Passwort *</FormHelperText>
                        <TextField
                            variant="standard"
                            required={true}
                            autoComplete="on"
                            margin="dense"
                            id="password"
                            name="password"
                            type="password"
                            inputProps={{
                                maxLength: 120
                            }}
                            value={password ?? ""}
                            onChange={e => setPassword(e.target.value)}
                        />
                    </FormControl>

                    <StyledButton
                        type="submit"
                        className={classes.saveButton}>
                        Anmelden
                    </StyledButton>
                </form>
            </Container>
        </React.Fragment>
    );
}

export default Login;