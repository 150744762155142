import React from 'react';
import { Fab } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';
import { CreateTaskContainer } from '../containers/createTaskContainer';
import { StyledButton } from './styledButton';

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    left: '30px',
    top: '150px'
  }
});

export const NewTask = () => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Fab color="primary" aria-label="add" onClick={handleClickOpen}>
        <AddIcon style={{ color: 'white' }} fontSize="large" />
      </Fab>
      {open && <CreateTaskContainer isOpen={open} closeSelf={() => handleClose()} />}
    </div>
  );
};
