import React from 'react';
import { Box, BoxProps, styled, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Droppable } from 'react-beautiful-dnd';
import { IBoardSection } from '../types/board';
import { useStore } from '../state/store';

interface IStyleProps {
  isActive: boolean;
  name: string;
  isVisible: boolean;
}

const useStylesListContainer = makeStyles((theme: Theme) => {
  return createStyles({
    listContainer: {
      padding: '8px',
      backgroundColor: (props: IStyleProps) => (props.isActive ? '#ddd' : props.isVisible ? '#eee' : 'inherit'),
      border: `4px ${theme.palette.primary.main}`,
      borderStyle: (props: IStyleProps) => (props.isActive ? 'solid' : ''),
      borderSpacing: '2',
      flexGrow: 1,
      minHeight: (props: IStyleProps) => (props.isActive ? '20px' : '20px'),
      borderRadius: '2px'
    }
  });
});

function DropArea(props: IStyleProps & Omit<BoxProps, keyof IStyleProps>) {
  const { name, isVisible } = props;
  const classes = useStylesListContainer(props);
  return <div className={classes.listContainer}>{isVisible && name}</div>;
}

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  margin: '0',
  height: '100%',
  width: '100%',
  fontSize: '1.5em',
  textAlign: 'center',
  color: theme.palette.primary.main
}));

export const DroppableArea = ({ section, isDropDisabled, name }: { section: IBoardSection; isDropDisabled: boolean; name: string }) => {
  const isVisible = useStore(state => state.isDropAreaVisible);
  return (
    <Container>
      <Droppable droppableId={section.id} isDropDisabled={isDropDisabled}>
        {(provided, snapshot) => (
          <div style={{ width: '100%' }} {...provided.droppableProps} ref={provided.innerRef}>
            <DropArea name={name} isActive={snapshot.isDraggingOver && snapshot.draggingOverWith !== null} isVisible={isVisible} />
          </div>
        )}
      </Droppable>
    </Container>
  );
};
